import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { fetchAllWorkflowSchemas } from '../../../Services/WorkflowService';

export const useEditComponentHandleUpgradeBlueprint = (setPending, selectedRows, setSelectSchemasWorkflow, setShowModal) => useCallback(async () => {

    setPending(true);

    try {

        let workflowId = selectedRows[0].workflow_id;

        const data = await fetchAllWorkflowSchemas(workflowId);

        setSelectSchemasWorkflow(data);

        setShowModal(true);

    } catch (error) {

        handleResponseError(error);

    } finally {

        setPending(false);

    }

}, [setPending, selectedRows, setSelectSchemasWorkflow, setShowModal]);