import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { deleteApikey } from '../../../Services/APIKeyService';
import { useGetApikeys } from './useGetApikeys';

export const useDeleteAPIKeyHandler = (setLoading, setPending, setDataComponent, setShowModalDelete, setInputDelete) => {

    const getApikeys = useGetApikeys(setPending, setDataComponent);

    return useCallback(async (apikey) => {

        setLoading(true);
        setShowModalDelete(false);

        try {

            await deleteApikey(apikey);

            getApikeys();

        } catch (error) {

            handleResponseError(error);

        } finally {

            setLoading(false);
            setInputDelete('');

        }

    }, [setLoading, setShowModalDelete, setInputDelete, getApikeys]);

};