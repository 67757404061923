import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGetWorkflowIntancesByWorkflowId } from '../Editor/Hooks/useGetWorkflowIntancesByWorkflowId';
import { customRowStyles } from '../Editor/js/styles';
import ModalHeader from './ModalHeader';
import { useDatatableColumnsInstance } from '../Editor/Hooks/useDatatableColumnsInstance';
import ModalFooter from './ModalFooter';

const ModalWorkflowInstances = ({ setShowModalWorkflowInstances, showModalWorkflowInstances, currentWorkflow, setLoading, setShowModalWorkflowInstanceEventsLogs, setShowAlert, setWorkflowExecutionId }) => {

    const [dataComponent, setDataComponent] = useState([]);
    const [pending, setPending] = useState(false);

    const { t } = useTranslation();

    const handleClick = (id) => {
        setWorkflowExecutionId(id);
        setShowAlert(false);
        setShowModalWorkflowInstances(false);
        setShowModalWorkflowInstanceEventsLogs(true);
    };

    const columns = useDatatableColumnsInstance(true, handleClick);

    const getWorkflowIntancesByWorkflowId = useGetWorkflowIntancesByWorkflowId(setPending, setLoading, setDataComponent);

    useEffect(() => {
        getWorkflowIntancesByWorkflowId(currentWorkflow.id);
    }, [currentWorkflow, getWorkflowIntancesByWorkflowId]);

    return (

        <>

            {showModalWorkflowInstances ? (
                <>
                    <div  className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        
                        <div className="relative w-auto my-6 mx-auto" style={{ width: '50%' }}>

                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                <ModalHeader title={t('events')} pending={pending} onSync={getWorkflowIntancesByWorkflowId} workflowExecutionId={currentWorkflow.id} />

                                <div className="relative p-6" style={{ maxHeight: '500px', overflowY: 'auto' }}>

                                    <DataTable
                                        columns={columns}
                                        data={dataComponent}
                                        progressPending={pending}
                                        pagination
                                        customStyles={customRowStyles} />

                                </div>


                                <ModalFooter fn={() => setShowModalWorkflowInstances(false)} label={t('close')} />
                                
                            </div>
                        </div>
                    </div>

                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
};

ModalWorkflowInstances.displayName = 'ModalWorkflowInstances';

ModalWorkflowInstances.propTypes = {
    setShowModalWorkflowInstances: PropTypes.func.isRequired,
    showModalWorkflowInstances: PropTypes.bool.isRequired,
    currentWorkflow: PropTypes.object,
    setLoading: PropTypes.func.isRequired,
    setShowModalWorkflowInstanceEventsLogs: PropTypes.func.isRequired,
    setShowAlert: PropTypes.func.isRequired,
    setWorkflowExecutionId: PropTypes.func.isRequired,
};

export default ModalWorkflowInstances;