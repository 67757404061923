const Node = () => {
    return {

        bpFunc: 'HttpRequest',

        title: 'Web Request',
        label: 'HttpRequest',
        icon: '/icon_action.png',
        leftHandler: true,
        params: [
            {
                name: 'method',
                alias: 'Method',
                value: 'GET',
                type: 'select',
                options: [
                    { label: 'GET', name: 'GET' },
                    { label: 'POST', name: 'POST' },
                    { label: 'PUT', name: 'PUT' },
                    { label: 'PATCH', name: 'PATCH' },
                    { label: 'DELETE', name: 'DELETE' },
                ],
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'url',
                alias: 'URL',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'body',
                alias: 'Body',
                value: '',
                type: 'textarea',
                required: false,
				direction: 'ParamInput'
            },
            {
                name: 'headers',
                alias: 'Headers',
                value: '',
                placeholder: '{\n    "Content-Type": "application/json",\n    "Authorization": "Bearer {token}"\n}',
                type: 'textarea',
                rows: 5,
                required: false,
				direction: 'ParamInput'
            },
            {
                name: 'data',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            },
            {
                name: 'status_code',
                value: '',
                type: 'integer',
                required: true,
				direction: 'ParamOutput'
            },
        ]
    };
};

export default Node;