import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { fetchAllApikeys } from '../../../Services/APIKeyService';

export const useRetrieveApikeys = () => useCallback(async () => {

    try {

        return await fetchAllApikeys();

    } catch (error) {

        handleResponseError(error);

    }

}, []);

export const useGetApikeys = (setPending, setDataComponent) => useCallback(async () => {

    setPending(true);

    try {

        const data = await fetchAllApikeys();

        setDataComponent(data);

    } catch (error) {

        handleResponseError(error);

    } finally {

        setPending(false);

    }

}, [setPending, setDataComponent]);