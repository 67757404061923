import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { fetchWorkflowById } from '../../../Services/WorkflowService';
import { useSetupReactFlow } from './useSetupReactFlow';
import { usePushSelectWorkflow } from './usePushSelectWorkflow';

export const useGetWorkflowById = (setCurrentWorkflow, setEdges, setNodes, setLoading, reactFlowInstance, setMaxIdNode, setMaxIdEdge) => {
   
    const loadFlow = useSetupReactFlow(setNodes, setEdges, reactFlowInstance, setMaxIdNode, setMaxIdEdge);

    const pushSelectWorkflow = usePushSelectWorkflow(setCurrentWorkflow, loadFlow);

    return useCallback(async (workflow_id) => {

        setLoading(true);

        try {

            const responseData = await fetchWorkflowById(workflow_id);

            pushSelectWorkflow(responseData);

        } catch (error) {
            handleResponseError(error);
        } finally {
            setLoading(false);
        }

    }, [pushSelectWorkflow, setLoading]);
};