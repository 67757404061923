import PropTypes from 'prop-types';

const StatusTag = ({ row }) => {

    let statusText;
    let statusClass;
    
    if (row.workflow_error === true) {

        statusText = 'Error';
        statusClass = 'error';
    
    } else if (row.workflow_error === false) {
        
        switch (row.level) {
            case 'WAITING':
            case 'RUNNING':
                statusText = 'In Progress';
                statusClass = 'warning';
                break;
            case 'STOPPED':
                statusText = 'Stopped';
                statusClass = 'stopped';
                break;
            default:
                statusText = 'Success';
                statusClass = 'success';
        }
    
    } else {

        switch (row.level) {
            case 'ERROR':
                statusText = 'Error';
                statusClass = 'error';
                break;
            case 'WAITING':
            case 'RUNNING':
                statusText = 'In Progress';
                statusClass = 'warning';
                break;
            case 'INFO':
                statusText = 'Info';
                statusClass = 'info';
                break;
            case 'DEBUG':
                statusText = 'Debug';
                statusClass = 'debug';
                break;
            case 'STOPPED':
                statusText = 'Stopped';
                statusClass = 'stopped';
                break;
            default:
                statusText = '';
                statusClass = '';
        }
    }
    
    return (
        <span className={`status-tag ${statusClass}`}>
            {statusText}
        </span>
    );
};

StatusTag.propTypes = {
    row: PropTypes.shape({
        workflow_error: PropTypes.bool,
        level: PropTypes.string.isRequired,
    }).isRequired,
};

export default StatusTag;
