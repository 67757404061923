import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';

const DeleteWorkflowModal = ({ setShowModalDelete, deleteWorkflowHandler, inputDelete, setInputDelete, workflowId }) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-6xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <form>
                            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-3xl font-semibold">
                                    {t('confirm_question')}
                                </h3>
                            </div>

                            <div className="relative p-6 flex-auto">
                                <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                    <div className="mt-2">
                                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded uppercase last:mr-0 mr-1">
                                        <Trans i18nKey="confirmDeletion" values={{ action: t('delete') }} components={{ 1: <b style={{ color: 'red' }} /> }} />
                                        </span>
                                        <input required type="text" value={inputDelete} onChange={(e) => setInputDelete(e.target.value)} className="px-3 py-3 mt-2 placeholder-slate-600 text-slate-800 relative bg-white bg-white rounded text-sm border-1 shadow outline-none focus:outline-none focus:ring w-full" />
                                    </div>

                                </div>
                            </div>

                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-gray-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => setShowModalDelete(false)}
                                >
                                    {t('close')}
                                </button>
                                <button
                                    disabled={inputDelete.toLowerCase() !== t('delete').toLowerCase()}
                                    className={`bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ${inputDelete.toLowerCase() !== t('delete').toLowerCase() ? 'opacity-50' : ''}`}
                                    onClick={() => deleteWorkflowHandler(workflowId)}
                                >
                                    {t('confirm')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

DeleteWorkflowModal.displayName = 'DeleteWorkflowModal';

DeleteWorkflowModal.propTypes = {
    setShowModalDelete: PropTypes.func.isRequired,
    deleteWorkflowHandler: PropTypes.func.isRequired,
    inputDelete: PropTypes.string.isRequired,
    setInputDelete: PropTypes.func.isRequired,
    workflowId: PropTypes.string.isRequired,
};

export default DeleteWorkflowModal;
