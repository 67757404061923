const Node = () => {
    return {
        
        bpFunc: 'Output',

        title: 'Output',
        label: 'Output',
        icon: '/icon_action.png',
        leftHandler: true,
        rightHandler: false,
        params: [],
        set_custom_params: true,
    };
};

export default Node;