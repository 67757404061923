const Node = () => {
    return {

        bpFunc: 'Input',

        title: 'Input',
        label: 'Input',
        icon: '/icon_action.png',
        leftHandler: false,
        rightHandler: true,
        params: [],
        set_custom_params: true,
    };
};

export default Node;