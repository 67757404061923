import { useCallback } from 'react';

export const useHandleUpdateNodeParamValue = (setNodes, setNodeDetails) => useCallback((nodeId, targetParamKey, paramIndex, newValue) => {

    setNodes((currentNodes) =>

        currentNodes.map((node) => {

            if (node.id === nodeId) {

                const paramsKey = targetParamKey === 'custom_params' ? 'custom_params' : 'params';

                let updatedParams = [...node.data[paramsKey]];

                updatedParams[paramIndex] = {
                    ...updatedParams[paramIndex],
                    value: newValue,
                };

                const updatedNodeData = {
                    ...node.data,
                    [paramsKey]: updatedParams,
                };

                setNodeDetails({
                    ...node,
                    data: updatedNodeData,
                });

                return {
                    ...node,
                    data: updatedNodeData,
                };

            }

            return node;
        })
    );

}, [setNodes, setNodeDetails]);