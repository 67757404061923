const Node = () => {
    return {

        bpFunc: 'Blueprint',

        title: 'Blueprint',
        label: 'Blueprint',
        icon: '/icon_action.png',
        leftHandler: true,
        rightHandler: true,
        params: [
            {
                name: 'workflow',
                alias: 'Workflow',
                value: '',
                type: 'select',
                options : [],
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'step',
                alias: 'Step',
                value: '',
                type: 'select',
                options : [],
                required: true,
				direction: 'ParamInput'
            },
        ],
        hide_nodeinspector: true,
        blueprint: true
    };
};

export default Node;