import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useMoneyFormat } from '../Editor/Hooks/useMoneyFormat';
import { useDateFormat } from '../Editor/Hooks/useDateFormat';
import { useDatatableColumnsInvoice } from '../Editor/Hooks/useDatatableColumnsInvoice';
import { customRowStyles } from '../Editor/js/styles';

const SummaryHeader = ({ title, dataComponent, description, moneyFormat, translate }) => {
        
    return (
        <div className="relative m-10 p-10 border border-gray-300 rounded-md text-left">
            <h1 className="text-2xl font-semibold mb-4">{title}</h1>
            <p className="text-2xl text-gray-600">{moneyFormat(dataComponent.amount)}</p>
            <p className="text-base text-gray-600 mt-5">{description}</p>
            <hr className="mt-5" />
            <SummaryDetails data={dataComponent}moneyFormat={moneyFormat} translate={translate} />
        </div>
    );
};

const SummaryDetails = ({ data, moneyFormat, translate }) => {
        
    return (
        <div className="flex justify-between items-start">
            <div>
                <h1 className="text-1xl font-semibold mb-4 mt-5">{ translate('reference_month') }</h1>
                <p className="text-base text-gray-600 mt-0">{ translate(data.month) }</p>
            </div>

            <div>
                <h1 className="text-1xl font-semibold mb-4 mt-5">{ translate('credits') }</h1>
                <p className="text-base text-gray-600 mt-0">{moneyFormat(0)}</p>
            </div>

            <div>
                <h1 className="text-1xl font-semibold mb-4 mt-5">{ translate('estimated_total_amount') }</h1>
                <p className="text-base text-gray-600 mt-0 text-right">{moneyFormat(data.amount)}</p>
            </div>
        </div>
    );
};

const LastUpdateInfo = ({ updateDate, dateFormat, translate }) => {
        
    return (
        updateDate ? (
            <div className="relative m-10 text-right">
                <p className="text-sm text-gray-600 mt-5">{translate('last_update')} {dateFormat(updateDate)}</p>
            </div>
        ) : null
    );
};

const DetailedSummary = ({ pending, dataComponent }) => {

    const { t } = useTranslation();

    const moneyFormat = useMoneyFormat();
    const dateFormat = useDateFormat();

    const columns = useDatatableColumnsInvoice()
    
    return (
        <>

            <SummaryHeader title={t('estimated_due_date')} dataComponent={dataComponent} description={t('estimate_description')} moneyFormat={moneyFormat} translate={t}/>
                
            <LastUpdateInfo updateDate={dataComponent.updated_at} dateFormat={dateFormat} translate={t} />

            {dataComponent.items && dataComponent.items.length > 0 && (

                    <div className='bg-gray-50 pt-10 pb-10'>

                        <div className="relative m-10 p-0 text-left">
                            <h1 className="text-1xl font-semibold mb-0">{ t('cumulative_monthly_summary') }</h1>
                            <p className="text-base text-gray-600 mt-1">{ t('charges_accounted_balance') }</p>
                        </div>

                        <div className="relative m-10 bg-white rounded-md shadow-md" style={{ border: '1px solid #e5e7eb', borderRadius: '0.375rem', padding: '2rem' }}>
                            <DataTable columns={columns} data={dataComponent.items} progressPending={pending} customStyles={customRowStyles} />
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default DetailedSummary;
