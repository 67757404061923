import { memo } from 'react';
import { Handle, Position } from 'reactflow';
import InputDisplay from '../Components/InputDisplay';
import OutputDisplay from '../Components/OutputDisplay';
import NodeIcon from '../Components/NodeIcon';
import PropTypes from 'prop-types';

const ActionNode = memo(({ data, id, type }) => {
    
    const { params, custom_params, leftHandler, rightHandler, bp_func_alias, bpFunc, icon, title, hide_output } = data;

    const inputParams = params.filter(param => param.direction === 'ParamInput');

    const customParams = custom_params.filter(param => param.name !== '');

    const targetHandle = leftHandler && (
        <Handle type="target" className="react-flow__handle--in" key={`in-${id}`} position={Position.Left} id="in" />
    );

    const sourceHandle = (rightHandler === undefined || rightHandler) && (
        <Handle type="source" position={Position.Right} key={`out-${id}`} className="react-flow__handle--out" id="out" />
    );

    return (
        <div className="bp-window">
            
            <div className="bp-label">{bp_func_alias || bpFunc}</div>
            
            <NodeIcon nodeIcon={icon} />

            <div className="bp-body">
                
                <div className='text-title' style={{ backgroundColor: '#f5f5f5', padding: '5px', borderRadius: '5px', marginBottom: '10px', textAlign: 'center' }}>
                    {title}
                </div>

                <InputDisplay dataForms={data} params={inputParams} nodeId={id} nodeType={type} fieldType="input_params" />

                {hide_output ? null : <OutputDisplay dataForms={data} params={params} nodeId={id} nodeType={type} fieldType="output_params"/>}
                
                {hide_output ? null : <OutputDisplay dataForms={data} params={customParams} nodeId={id} nodeType={type} fieldType="custom_params"/>}
            
            </div>

            {sourceHandle}
        
            {targetHandle}
        
        </div>
    );
});

ActionNode.displayName = 'ActionNode';

ActionNode.propTypes = {
    data: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default ActionNode;

