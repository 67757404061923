import axios from '../Config/axios';

export async function fetchCurrentInvoice() {

    try {

        const response = await axios.get('/api/invoice');

        return response.data;

    } catch (error) {

        throw new Error('Error fetching current invoice');

    }
}