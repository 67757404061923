import { useCallback } from 'react';

export const useUpdateNodeDetails = (reactFlowInstance) => useCallback((newDetails) => {

    if (!reactFlowInstance || !newDetails) return;

    reactFlowInstance.setNodes((prevNodes) =>
        prevNodes.map(node => node.id === newDetails.id
            ? { ...node, data: newDetails.data }
            : node
        )
    );

}, [reactFlowInstance]);