import PropTypes from 'prop-types';

const ExpandedComponent = ({ data }) => (
  <pre>
    <div style={{ whiteSpace: 'pre-wrap', fontSize: '12px', padding: '50px', borderRadius: '0.375rem' }}>
      {data.payload}
    </div>
  </pre>
);

ExpandedComponent.displayName = 'ExpandedComponent';

ExpandedComponent.propTypes = {
  data: PropTypes.object.isRequired
};

export default ExpandedComponent;