const ModalFooter = ({ fn, label }) => (
    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
        <button
            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => fn()} >
            {label}
        </button>
    </div>
);

export default ModalFooter;
