import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { updateWorkflow } from '../../../Services/WorkflowService';
import { useSetupReactFlow } from './useSetupReactFlow';
import { usePushSelectWorkflow } from './usePushSelectWorkflow';

export const useUpdateWorkflowHandler = (reactFlowInstance, setLoading, setShowEvents, setAlertMessage, setShowAlert, setShowErrorAlert, setNodes, setEdges, setMaxIdNode, setMaxIdEdge, setCurrentWorkflow) => {

    const loadFlow = useSetupReactFlow(setNodes, setEdges, reactFlowInstance, setMaxIdNode, setMaxIdEdge);

    const pushSelectWorkflow = usePushSelectWorkflow(setCurrentWorkflow, loadFlow);

    return useCallback(async (event, bpName, WorkflowId) => {

        event.preventDefault();

        if (!reactFlowInstance) return;

        setLoading(true);

        try {

            const flow = reactFlowInstance.toObject();

            let title = bpName;

            let workflow_json = JSON.stringify(flow);

            const data = { title, workflow_json };

            const responseData = await updateWorkflow(WorkflowId, data);

            pushSelectWorkflow(responseData);

            setShowEvents(false);
            setAlertMessage(responseData.message);
            setShowAlert(true);

        } catch (error) {
            setAlertMessage(error.message);
            setShowErrorAlert(true);

        } finally {
            setLoading(false);
        }

    }, [reactFlowInstance, pushSelectWorkflow, setLoading, setShowEvents, setAlertMessage, setShowAlert, setShowErrorAlert]);

};