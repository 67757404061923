import PropTypes from 'prop-types';

function SyncButton({ onSync, workflowExecutionId }) {
    
    const handleClick = () => {
        if (workflowExecutionId !== undefined) {
            onSync(workflowExecutionId);
        } else {
            onSync();
        }
    };

    return (
        <span className="text-xs text-gray-500 text-right">
            <div className="bp-icon">
                <span>
                    <button onClick={handleClick}>
                        <img alt="Sync icon" style={{ height: '20px' }} src="https://static.thenounproject.com/png/438749-200.png" />
                    </button>
                </span>
            </div>
        </span>
    );
}


SyncButton.displayName = 'SyncButton';

SyncButton.propTypes = {
    onSync: PropTypes.func.isRequired,
    workflowExecutionId: PropTypes.string
};

export default SyncButton;