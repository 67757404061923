import PropTypes from 'prop-types';

function DisplayTypeComponent({ type, value }) {
    
    const typeColorMap = {
        'boolean': '#008080',
        'integer': '#0984e3',
        'float': '#0000FF',
        'string': '#FFA500',
        'textarea': '#3498db',
        'select': '#00b894',
    };

    const color = typeColorMap[type] || 'black';

    let displayValue = value ? value : type;

    return (
        <span style={{ color }}>{displayValue}</span>
    );
}

DisplayTypeComponent.displayName = 'DisplayTypeComponent';

DisplayTypeComponent.propTypes = {
    type: PropTypes.string,
    value: PropTypes.any
};

export default DisplayTypeComponent;