import { useState, useEffect } from 'react';
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import Header from './Components/Header';
import Sidebar from './Components/Sidebar';
import { useTranslation } from 'react-i18next';
import { useGetCurrentInvoice } from './Editor/Hooks/useGetCurrentInvoice';
import DetailedSummary from './Components/DetailedSummary';
import Loading from './Components/Loading';
import PageHeader from './Components/PageHeader';

const Invoices = () => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);
    const [dataComponent, setDataComponent] = useState([]);
    const [pending, setPending] = useState(false);

    const breadcrumbItems = [
        { label: 'Dashboard', link: '/dashboard' },
        { label: t('sidebar.invoices'), link: '' },
    ];

    const getCurrentInvoice = useGetCurrentInvoice(setLoading, setPending, setDataComponent);

    useEffect(() => {
        getCurrentInvoice();
    }, [getCurrentInvoice]);

    return (

        <ReactFlowProvider>

            {
                loading ? (
                    <Loading />
                ) : (

                    <div className="flow-wrapper">

                        <Header />

                        <div className="flow-editor">

                            <Sidebar isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} />

                            <div className="flow-editor-wrapper">

                                <PageHeader title={t('sidebar.invoices')} breadcrumbItems={breadcrumbItems} />

                                {dataComponent &&
                                    <DetailedSummary pending={pending} dataComponent={dataComponent} />
                                }

                            </div>
                        </div>
                    </div>
                )
            }
        </ReactFlowProvider>
    );
};

export default Invoices;