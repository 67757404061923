import { useTranslation } from 'react-i18next';

import StatusTag from '../../Components/StatusTag';
import ButtonRedirect from '../../Components/ButtonRedirect';

const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const dateString = date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const timeString = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    return `${dateString} às ${timeString}`;
};

const formatExecutionTime = (microseconds) => {
    return `${(microseconds / 1000000).toFixed(2)}s`;
};

export const useDatatableColumnsInstance = (isModal, handleClick) => {

    const { t } = useTranslation();

    const columns = [
        {
            name: <span className="text-base">{t('code')}</span>,
            selector: row => row.id,
            grow: 4,
        },
        {
            name: <span className="text-base">{t('automation')}</span>,
            selector: row => row.workflow_title,
            grow: 4,
        },
        {
            name: <span className="text-base">{t('date')}</span>,
            selector: row => formatDate(row.updated_at),
            grow: 4,
        },
        {
            name: <span className="text-base">{t('status')}</span>,
            selector: row => <StatusTag row={row} />,
            grow: 2 ,
        },
        {
            name: <span className="text-base">{t('execution_time')}</span>,
            selector: row => formatExecutionTime(row.microseconds),
            grow: 2,
        }
    ];

    if (isModal) {
        columns.push({
            name: '',
            cell: row => <button onClick={() => handleClick(row.id)}>{t('btn.logs')}</button>,
            button: true,
            grow: 2,
        });
    } else {
        columns.push({
            name: '',
            selector: row => <ButtonRedirect id={row.id} path='/workflows/instance' label={ t('btn.logs') } />,
            button: true,
            grow: 2,
        });
    }

    return columns;
};