const Node = () => {
    return {
        bpFunc: 'Api',

        title: 'Webhook',
        label: 'Webhook',
        icon: '/icon_webhook.png',
        leftHandler: false,
        params: [
            {
                name: 'trigger',
                alias: 'Trigger',
                value: '',
                type: 'string',
                required: true,
                disabled: false,
				direction: 'ParamInput'
            },
            {
                name: 'auth',
                alias: 'Autenticação',
                value: '',
                type: 'select',
                options : [],
                required: false,
				direction: 'ParamInput'
            },
            {
                name: 'payload',
                alias: 'Payload',
                value: '',
                type: 'textarea',
                required: true,
                direction: 'ParamInput',
                hidden: true
            }
        ]
    };
};

export default Node;