import { useCallback } from 'react';
import { getEdgeId, setMaxIdEdge, setMaxIdNode, getNodeId} from '../Utils';

export const useSetupReactFlow = (setNodes, setEdges, reactFlowInstance) => useCallback((flow) => {

    if (!reactFlowInstance) return;

    const { x = 0, y = 0, zoom = 1 } = flow.viewport;
    setNodes(flow.nodes || []);
    setEdges(flow.edges || []);
    reactFlowInstance.setViewport({ x, y, zoom });

    let nodeId = getNodeId();
    let edgeId = getEdgeId();

    flow.nodes.forEach((node) => { nodeId = Math.max(nodeId, parseInt(node.id) + 1); });
    flow.edges.forEach((edge) => { edgeId = Math.max(edgeId, parseInt(edge.id) + 1); });

    setMaxIdNode(nodeId);
    setMaxIdEdge(edgeId);

}, [setNodes, setEdges, reactFlowInstance]);