import { useState, useEffect, useMemo } from 'react';
import MarketplaceApps from '../Components/MarketplaceApps';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGetWorkflowsTypeFunction } from './Hooks/useGetWorkflows';
import { useRenderButtons } from './Hooks/useRenderButtons';

const Sidebar = ({
    installedAppsActions,
    currentWorkflow,
}) => {

    const { t } = useTranslation();

    const [actionList, setActionList] = useState([]);
    const [triggerList, setTriggerList] = useState([]);

    const BP_TYPE_FUNCTION = 'function';

    const trigger_list = useMemo(() => [
        { id: 'event', label: 'Event', image: 'https://static.thenounproject.com/png/6315198-200.png' },
        { id: 'schedule', label: 'Schedule', image: 'https://static.thenounproject.com/png/2080545-200.png' },
        { id: 'api', label: 'Webhook', image: 'https://static.thenounproject.com/png/5808569-200.png' },
    ], []);

    const action_list = [
        { id: 'branch', label: 'Branch', image: 'https://static.thenounproject.com/png/718608-200.png' },
        { id: 'for', label: 'For', image: 'https://static.thenounproject.com/png/94237-200.png' },
        { id: 'for_each', label: 'For Each', image: '' },
        { id: 'delay', label: 'Delay', image: 'https://static.thenounproject.com/png/1570075-200.png' },
        { id: 'switch', label: 'Switch', image: 'https://static.thenounproject.com/png/5342676-200.png' },
        { id: 'smtp', label: 'Send E-mail', image: '' },
        { id: 'save_variable', label: 'SaveVariable', image: '' },
        { id: 'random_number', label: 'Random Number', image: '' },
        { id: 'random_name', label: 'Random Name', image: '' },
        { id: 'url_encode', label: 'URL Encode', image: 'https://static.thenounproject.com/png/4456219-200.png' },
        { id: 'url_decode', label: 'URL Decode', image: 'https://static.thenounproject.com/png/4456236-200.png' },
        { id: 'print', label: 'Print', image: '' },
        { id: 'date_diff', label: 'DateDiff', image: 'https://static.thenounproject.com/png/4411743-200.png' },
        { id: 'dates_of_month', label: 'DatesOfMonth', image: '' },
        { id: 'find', label: 'Find', image: 'https://static.thenounproject.com/png/6136688-200.png' },
        { id: 'get_date', label: 'GetDate', image: '' },
        { id: 'http_request', label: 'Web Request', image: 'https://static.thenounproject.com/png/60826-200.png' },
        { id: 'is_weekend_day', label: 'IsWeekendDay', image: '' },
        { id: 'last_day_current_month', label: 'LastDayMonth', image: '' },
        { id: 'uppercase', label: 'ToUpper', image: 'https://static.thenounproject.com/png/2017456-200.png' },
        { id: 'lowercase', label: 'ToLower', image: 'https://static.thenounproject.com/png/2017458-200.png' },
        { id: 'break', label: 'Break', image: 'https://static.thenounproject.com/png/1784917-200.png' },
        { id: 'splitter', label: 'Splitter', image: 'https://static.thenounproject.com/png/32625-200.png' },
        { id: 'mapper', label: 'Mapper', image: 'https://static.thenounproject.com/png/979049-200.png' },
        { id: 'join', label: 'Join', image: 'https://static.thenounproject.com/png/70613-200.png' },
        { id: 'concat', label: 'Concat', image: 'https://static.thenounproject.com/png/3117262-200.png' },
        { id: 'subtract_date', label: 'SubtractDate', image: 'https://static.thenounproject.com/png/322574-200.png' },
        { id: 'ucfirst', label: 'UcFirst', image: '' },
        { id: 'variable', label: 'Variable', image: '' },
        { id: 'math_expression', label: 'Math Expression', image: 'https://static.thenounproject.com/png/6780051-200.png' },
        { id: 'unset', label: 'Unset', image: 'https://static.thenounproject.com/png/6315198-200.png' },
    ];

    const onDragStart = (event, node, externalApp) => {

        console.log(node);

        let nodeType = node.id;

        if (node.function){
            nodeType = 'blueprint';
        }

        event.dataTransfer.setData('application/nodeType', nodeType);

        event.dataTransfer.setData('application/nodeData', JSON.stringify(node));

        if (nodeType === 'marketplace') {
            event.dataTransfer.setData('application/marketplace_app', JSON.stringify(externalApp));
        }

        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setDragImage(document.getElementById('draggableContainer'), 30, 30);

    };

    const getWorkflowsTypeFunction = useGetWorkflowsTypeFunction(action_list, setActionList);

    const renderButtons = useRenderButtons(onDragStart);

    useEffect(() => {

        if (currentWorkflow){
        
            if (currentWorkflow.type !== BP_TYPE_FUNCTION) {
                const sortedTriggerList = [...trigger_list].sort((a, b) => a.label.localeCompare(b.label));
                setTriggerList(sortedTriggerList);
            }

            if (actionList.length === 0){
                getWorkflowsTypeFunction(currentWorkflow);
            }
        }

    }, [currentWorkflow]);

    return (

        <aside className="sidebar">

            <div id="draggableContainer"></div>

            {currentWorkflow && currentWorkflow.type !== BP_TYPE_FUNCTION &&
                <div>
                    <h1 className="p-3 px-7 border-b-[1px]  border-gray-200 uppercase text-xs text-gray-500">{t('triggers')}</h1>
                    <div className="wrapper border-b-[1px]">
                        <div className="grid grid-cols-2 gap-3">
                            {renderButtons(triggerList)}
                        </div>
                    </div>
                </div>
            }

            <h1 className="p-3 px-7 border-b-[1px] border-gray-200 uppercase text-xs text-gray-500">{t('actions')}</h1>
           
            <div className="wrapper mb-5">
                <div className="grid grid-cols-2 gap-3">
                    {renderButtons(actionList)}
                    <MarketplaceApps onDragStart={onDragStart} installedAppsActions={installedAppsActions} />
                </div>
            </div>

        </aside>
    );
};

Sidebar.displayName = 'Sidebar';

Sidebar.propTypes = {
    installedAppsActions: PropTypes.array,
    currentWorkflow: PropTypes.object,
};

export default Sidebar;