import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { executeWorkflowSchemaByNode } from '../../../Services/WorkflowService';

export const useHandleExecuteWorkflowSchemaByNode = (setShowModal, getWorkflowIntances, selectedWorkflow, selectedRows, setSelectedRows) => useCallback(async (selectVersion, selectedNode) => {

    try {

        const data = await executeWorkflowSchemaByNode(selectedWorkflow, selectVersion, selectedNode, selectedRows);

        if (data) {
            setShowModal(false);
            setSelectedRows([]);
            getWorkflowIntances();
        }

    } catch (error) {
        handleResponseError(error);
    }

}, [setShowModal, getWorkflowIntances, selectedWorkflow, selectedRows, setSelectedRows]);