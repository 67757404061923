import { useCallback } from 'react';

export const useSetFlow = (reactFlowInstance) => useCallback(() => {
    if (!reactFlowInstance) return;

    const workflow = reactFlowInstance.toObject();
    if (workflow.nodes.length > 0) {
        const workflowString = JSON.stringify(workflow);
        localStorage.setItem('workflow', workflowString);
    }
}, [reactFlowInstance]);