import { useState, useEffect } from 'react';
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import DataTable from 'react-data-table-component';

import Header from './Components/Header';
import Sidebar from './Components/Sidebar';
import SyncButton from './Components/SyncButton';
import ModalUpgradeInstances from './Components/ModalUpgradeInstances';
import AlertBar from './Components/AlertBar';
import { useTranslation } from 'react-i18next';
import { useGetWorkflows } from './Editor/Hooks/useGetWorkflows';
import { useHandleExecuteWorkflowSchemaByNode } from './Editor/Hooks/useHandleExecuteWorkflowSchemaByNode';
import { useGetWorkflowIntances } from './Editor/Hooks/useGetWorkflowIntances';
import { useFilterComponentHandleUpgradeBlueprint } from './Editor/Hooks/useFilterComponentHandleUpgradeBlueprint';
import { useEditComponentHandleUpgradeBlueprint } from './Editor/Hooks/useEditComponentHandleUpgradeBlueprint';
import { useHandleRowSelected } from './Editor/Hooks/useHandleRowSelected';

import HeaderComponent from './Components/HeaderComponent';
import { useDatatableColumnsInstance } from './Editor/Hooks/useDatatableColumnsInstance';
import PageHeader from './Components/PageHeader';
import { customRowStyles } from './Editor/js/styles';

const Instances = () => {

    const [selectedRows, setSelectedRows] = useState([]);

    const [workflowInstancesComponent, setWorkflowInstancesComponent] = useState([]);
    const [pending, setPending] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showEvents, setShowEvents] = useState(false);
    const [showModalWorkflowInstanceEventsLogs, setShowModalWorkflowInstanceEventsLogs] = useState(false);

    const [inputStartDate, setInputStartDate] = useState('');
    const [inputEndDate, setInputEndDate] = useState('');
    const [selectStatus, setSelectStatus] = useState('');
    const [workflowsComponent, setWorkflowsComponent] = useState([]);
    const [selectedWorkflow, setSelectedWorkflow] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [filterDisabled, setFilterDisabled] = useState(true);
    const [selectSchemasWorkflow, setSelectSchemasWorkflow] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const { t } = useTranslation();

    const breadcrumbItems = [
        { label: 'Dashboard', link: '/dashboard' },
        { label: t('sidebar.instances'), link: '/instances' }
    ];

    const handleSelectWorkflow = (event) => {
        setSelectedWorkflow(event.target.value);
    };

    const handleInputStartDate = (event) => {
        setInputStartDate(event.target.value);
    };

    const handleInputEndDate = (event) => {
        setInputEndDate(event.target.value);
    };

    const handleSelectStatus = (event) => {
        setSelectStatus(event.target.value);
    };

    const handleRowSelected = useHandleRowSelected(setSelectedRows, setSelectedWorkflow, setFilterDisabled);

    const getWorkflowIntances = useGetWorkflowIntances(setPending, setInputStartDate, setInputEndDate, setSelectStatus, setWorkflowInstancesComponent);

    const getWorkflows = useGetWorkflows(setPending, setWorkflowsComponent);

    const EditComponentHandleUpgradeBlueprint = useEditComponentHandleUpgradeBlueprint(setPending, selectedRows, setSelectSchemasWorkflow, setShowModal);

    const FilterComponentHandleUpgradeBlueprint = useFilterComponentHandleUpgradeBlueprint(setPending, inputStartDate, inputEndDate, selectStatus, selectedWorkflow, handleRowSelected, setWorkflowInstancesComponent);

    const HandleExecuteWorkflowSchemaByNode = useHandleExecuteWorkflowSchemaByNode(setShowModal, getWorkflowIntances, selectedWorkflow, selectedRows, setSelectedRows);

    const columns = useDatatableColumnsInstance();

    useEffect(() => {

        setSelectedWorkflow('');

        getWorkflows();

        getWorkflowIntances();

    }, [getWorkflowIntances, getWorkflows]);

    return (

        <ReactFlowProvider>

            <div>
                <div className="flow-wrapper">

                    <Header />

                    <div className="flow-editor">

                        <Sidebar isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} />

                        <div className="flow-editor-wrapper">

                            <PageHeader title={t('sidebar.instances')} breadcrumbItems={breadcrumbItems} />

                            {showModal && <ModalUpgradeInstances HandleExecuteWorkflowSchemaByNode={HandleExecuteWorkflowSchemaByNode} setShowModal={setShowModal} selectSchemasWorkflow={selectSchemasWorkflow} />}

                            <div className="relative m-10" style={{ border: '1px solid #e5e7eb', borderRadius: '0.375rem', padding: '2rem' }}>

                                {!pending && (
                                    <SyncButton onSync={getWorkflowIntances} />
                                )}

                                <DataTable columns={columns} data={workflowInstancesComponent} progressPending={pending} pagination paginationPerPage={10} paginationRowsPerPageOptions={[10, 20, 50, 100, 500, 1000]} subHeader selectableRows onSelectedRowsChange={handleRowSelected} persistTableHead noDataComponent={<div style={{ padding: 24 }}>{t('no_records')}</div>} customStyles={customRowStyles} 
                                subHeaderComponent={
                                    <HeaderComponent
                                        selectedWorkflow={selectedWorkflow}
                                        handleSelectWorkflow={handleSelectWorkflow}
                                        inputStartDate={inputStartDate}
                                        handleInputStartDate={handleInputStartDate}
                                        inputEndDate={inputEndDate}
                                        handleInputEndDate={handleInputEndDate}
                                        selectStatus={selectStatus}
                                        handleSelectStatus={handleSelectStatus}
                                        workflowsComponent={workflowsComponent}
                                        filterDisabled={filterDisabled}
                                        selectedRows={selectedRows}
                                        FilterComponentHandleUpgradeBlueprint={FilterComponentHandleUpgradeBlueprint}
                                        EditComponentHandleUpgradeBlueprint={EditComponentHandleUpgradeBlueprint} />
                                    } />

                            </div>

                        </div>

                    </div>

                </div>

                <div className="flow-editor-wrapper"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    {showAlert && <AlertBar showErrorAlert={showErrorAlert} setShowErrorAlert={setShowErrorAlert} message={alertMessage} setAlertMessage={setAlertMessage} setShowAlert={setShowAlert} showAlert={showAlert} setShowEvent={setShowEvents} showEvents={showEvents} showModalWorkflowInstanceEventsLogs={showModalWorkflowInstanceEventsLogs} setShowModalWorkflowInstanceEventsLogs={setShowModalWorkflowInstanceEventsLogs} />}
                    
                </div>
            </div>

        </ReactFlowProvider>
    );
};

export default Instances;