import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

function EditableDisplayName({ index, param, onUpdate }) {
    
    const [isEditing, setIsEditing] = useState(false);
    const [editingValue, setEditingValue] = useState('');

    const handleChange = (e) => {
        setEditingValue(e.target.value);
    };

    const handleBlur = () => {

        if (editingValue !== param.name) {
            onUpdate(index, editingValue);
        }

        setIsEditing(false);
    };

    const handleClick = () => {
        setEditingValue(param.name);
        setIsEditing(true);
    };
    
    return (
        <div>
            {isEditing && !param.required ? (
                <input
                    type="text"
                    style={{ height: '10px', width: '100px', fontSize: '10px', padding: '8px' }}
                    className="px-2 input-style py-1 placeholder-slate-300 text-slate-600 relative bg-white rounded text-sm border-1 shadow outline-none focus:outline-none focus:ring w-full"
                    value={editingValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                />
            ) : (
                <label onClick={handleClick}>
                    <b>
                        <span className='text-label'>{`{${param.name}}`} </span>
                        {!param.required ? (
                            <FontAwesomeIcon icon={faPen} className="icon-edit" />
                        ) : null}
                    </b>
                </label>
            )}
        </div>
    );
}

EditableDisplayName.displayName = 'EditableDisplayName';

EditableDisplayName.propTypes = {
    index: PropTypes.number.isRequired,
    param: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired
};

export default EditableDisplayName;