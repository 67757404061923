import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ModalUpgradeInstances = ({setShowModal, selectSchemasWorkflow, HandleExecuteWorkflowSchemaByNode}) => {

    const [selectVersion, setSelectVersion] = useState('');
    const [selectNode, setSelectNode] = useState([]);
    const [selectedNode, setSelectedNode] = useState('');
    const [actionDisabled, setActionDisabled] = useState(true);

    const { t } = useTranslation();

    const handleSelectVersion = useCallback((schemaId) => {

        console.log(schemaId);
        
        for (const schema of selectSchemasWorkflow) {
            if (schema.id === schemaId) {
                let workflowJson = JSON.parse(schema.workflow_json);
                setActionDisabled(false);
                setSelectedNode(workflowJson.nodes[0].id);
                setSelectNode(workflowJson.nodes);
                break;
            }
        }
        
        setSelectVersion(schemaId);

    }, [selectSchemasWorkflow, setActionDisabled, setSelectedNode, setSelectNode, setSelectVersion]);

    const handleSelectNode = (event) => {
        setActionDisabled(false);
        setSelectedNode(event.target.value);
    };

    useEffect(() => {
        if (selectSchemasWorkflow && selectSchemasWorkflow.length > 0) {
            handleSelectVersion(selectSchemasWorkflow[0].id);
        }
    }, [selectSchemasWorkflow, handleSelectVersion]);
    
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-6xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">
                                {t('upgradeInstances')}
                            </h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                <div className="mt-2">
                                    <span className="text-xs font-semibold inline-block py-1 px-2 text-center uppercase rounded uppercase last:mr-0 mr-1">
                                       {t('enterWorkflowVersion')}
                                    </span>
                                    
                                    <select
                                        className=" input-style"
                                        onChange={(e) => handleSelectVersion(e.target.value)}
                                        style={{ height: '40px', fontSize: '12px' }}
                                    >
                                        <option disabled value="">{t('selectDesiredVersion')}</option>

                                        {selectSchemasWorkflow.map((option, optionIndex) => (
                                            <option key={optionIndex} value={option.id}>
                                                {optionIndex === 0 ? t('latestVersion') : t('version') + ` ${selectSchemasWorkflow.length - optionIndex}`}
                                            </option>
                                        ))}
                                    </select>

                                    {selectVersion && (
                                        <div className="mt-2">
                                            <span className="text-xs font-semibold inline-block py-1 px-2 text-center uppercase rounded uppercase last:mr-0 mr-1">
                                                {t('enterInitialNodeName')}
                                            </span>
                                            
                                            
                                            <select
                                                className=" input-style"
                                                onChange={handleSelectNode}
                                                style={{ height: '40px', fontSize: '12px' }}
                                            >
                                                <option disabled value="">{t('enterNodeName')}</option>

                                                {selectNode.map((option, optionIndex) => (
                                                    <option key={optionIndex} value={option.id}>
                                                        {option.data.title} ({t('node')} #{option.id})
                                                    </option>
                                                ))}

                                            </select>

                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => setShowModal(false)}>
                                {t('close')}
                            </button>
                            <button disabled={actionDisabled} onClick={() => HandleExecuteWorkflowSchemaByNode(selectVersion, selectedNode)} className={`bg-green-500 text-white ${actionDisabled ? 'disabled' : ''} active:bg-green-600 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}>
                                {t('reexecute')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

ModalUpgradeInstances.displayName = 'ModalUpgradeInstances';

ModalUpgradeInstances.propTypes = {
    setShowModal: PropTypes.func.isRequired,
    selectSchemasWorkflow: PropTypes.array.isRequired,
    HandleExecuteWorkflowSchemaByNode: PropTypes.func.isRequired,
};

export default ModalUpgradeInstances;