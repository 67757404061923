import { useTranslation } from 'react-i18next';

import StatusTag from '../../Components/StatusTag';

function renderStatusTag(row) {
    return <StatusTag row={row} />;
}

function formatDate(timestamp) {
    const date = new Date(timestamp);
    const dateString = date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const timeString = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    return `${dateString} às ${timeString}.${milliseconds}`;
}

function formatExecutionTime(duration) {
    const seconds = (duration / 1000000).toFixed(2);
    return `${seconds}s`;
}

function renderButtonRetry(row, getExecutionActionByIdAndNode, workflowExecutionId) {
    if (row.level === 'ERROR') {
        return (
            <button onClick={() => getExecutionActionByIdAndNode(workflowExecutionId, row.current_step)}>
                <img style={{ height: '20px' }} src="/retry.png" alt="Retry" title='Retry' />
            </button>
        );
    }
    return null;
}

export const useDatatableColumnsEvent = (getExecutionActionByIdAndNode, workflowExecutionId) => {

    const { t } = useTranslation();

    const columns = [
        {
            name: <span className="text-base">{t('title')}</span>,
            selector: row => row.title,
            grow: 2,
        },
        {
            name: <span className="text-base">{t('event')}</span>,
            selector: row => row.event,
            grow: 2,
        },
        {
            name: <span className="text-base">{t('step')}</span>,
            selector: row => row.current_step,
            grow: 2,
        },
        {
            name: <span className="text-base">{t('level')}</span>,
            selector: renderStatusTag,
            grow: 2,
        },
        {
            name: <span className="text-base">{t('date')}</span>,
            selector: row => formatDate(row.updated_at),
            grow: 2,
        },
        {
            name: <span className="text-base">{t('execution_time')}</span>,
            selector: row => formatExecutionTime(row.duration),
            grow: 2,
        },
        {
            name: '',
            cell: (row) => renderButtonRetry(row, getExecutionActionByIdAndNode, workflowExecutionId),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            grow: 2,
        },
        
    ];

    return columns;
};