import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './Config/i18n';

const Loading = () => <div className="flow-editor grid h-screen place-items-center text-gray-500">Loading</div>;

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <Router>
        <App />
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
