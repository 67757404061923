import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { fetchCurrentInvoice } from '../../../Services/InvoiceService';

export const useGetCurrentInvoice = (setLoading, setPending, setDataComponent) => useCallback(async () => {

    setLoading(true);
    setPending(true)

    try {

        const data = await fetchCurrentInvoice();

        setDataComponent(data);

    } catch (error) {

        handleResponseError(error);

    } finally {

        setLoading(false);
        setPending(false);

    }

}, [setLoading, setPending, setDataComponent]);