import PropTypes from 'prop-types';

const InputParam = ({ param, index, handleChange }) => {

    const renderSection = () => {

        if (param.hidden) {
            return null;
        }

        return (

            <div key={index} style={{ backgroundColor: '#ecf0f1', marginBottom: '15px', border: '1px solid #ccc', borderRadius: '5px', paddingBottom: '15px', padding: '10px' }}>
                <div className="flex justify-between">
                    <h3 className={`pb-3 text-xs ${!param.alias ? 'uppercase' : ''} text-gray-500`}>
                        <span className="text-xs font-semibold inline-block py-1 px-2 rounded text-blue-600 bg-blue-200 last:mr-0 mr-1">
                            {param.alias ? param.alias : param.name}
                        </span>
                        {param.required && <span style={{ color: '#e74c3c' }}>*</span>}
                    </h3>

                    <span className="text-xs text-gray-500">
                        <span className="text-xs inline-block py-1 px-2 rounded text-neutral-600 bg-neutral-200 last:mr-0 mr-1">
                            {param.type}
                        </span>
                    </span>
                </div>
                {renderInputField()}
            </div>
        );

    };
    
    const renderInputField = () => {

        switch (param.type) {

            case 'textarea':
                return (
                    <textarea
                        value={param.value}
                        className="textarea-style"
                        onChange={(e) => handleChange(e, index)}
                        style={{ resize: 'vertical', height: 'auto', padding: '5px' }}
                        disabled={param.disabled}
                        rows={param.rows || 3}
                        placeholder={param.placeholder || ''}
                    ></textarea>
                );
            case 'select':
                return (
                    <select
                        value={param.value}
                        onChange={(e) => handleChange(e, index)}
                        className="input-style"
                        style={{ height: '30px', width: '100%', fontSize: '12px', padding: '0px 0px 0px 5px' }}
                        disabled={param.disabled}
                    >
                        {param.options.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                );
            default:

                return (
                    <input
                        className="input-style"
                        value={param.value}
                        type="text"
                        onChange={(e) => handleChange(e, index)}
                        style={{ height: '30px', padding: '5px' }}
                        disabled={param.disabled}
                        placeholder={param.placeholder || ''}
                    />
                );
        }
    };

    return (

        <div className="flex flex-col">
            {renderSection()}
        </div>

    );
};

InputParam.displayName = 'InputParam';

InputParam.propTypes = {
    param: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired
};

export default InputParam;