import DisplayTypeComponent from './DisplayTypeComponent';
import PropTypes from 'prop-types';

const ParamsDisplay = ({ param }) => {
    return (
        <div className="flex justify-between" style={{ marginBottom: '5px', paddingBottom: '5px' }}>
            <label><b>{`{${param.name}}`}</b></label>
            <DisplayTypeComponent type={param.type} />
        </div>
    );  
}


const NodeInspectorOutputComponent = ({ nodeDetails }) => {
    
    return (
        <div>
            {
                ((nodeDetails.data.params && nodeDetails.data.params.filter(param => param.direction === 'ParamOutput').length > 0) ||
                    (nodeDetails.data.custom_params && nodeDetails.data.custom_params.filter(param => param.name !== '').length > 0)) && (
                    <>
                        <h1 className='mb-3'>Saída:</h1>
                    </>
                )
            }

            {nodeDetails.data.params && nodeDetails.data.params
                .filter(param => param.direction === 'ParamOutput')
                .map((param, index) => (
                    <ParamsDisplay key={index} param={param} />
                ))
            }

            {nodeDetails.data.custom_params && nodeDetails.data.custom_params
                .filter(param => param.name !== '')
                .map((param, index) => (
                    <ParamsDisplay key={index} param={param} />
                ))
            }

            <div style={{ paddingTop: '10px' }}>
                <div className="flex justify-end">
                    <span className="text-xs inline-block py-1 px-2 rounded text-gray-600 bg-gray-200 last:mr-0 mr-1">
                        Node: #{nodeDetails.id}
                    </span>
                </div>
            </div>
        </div>
    );
};

NodeInspectorOutputComponent.displayName = 'NodeInspectorOutputComponent';

NodeInspectorOutputComponent.propTypes = {
    nodeDetails: PropTypes.object.isRequired
};

export default NodeInspectorOutputComponent;
