const Node = () => {
    return {

        bpFunc: 'Webhook',

        title: 'Webhook',
        label: 'POST',
        icon: '/icon_action.png',
        leftHandler: true,
        params: [
            {
                name: 'url',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'body',
                value: '',
                type: 'textarea',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'output',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            }
        ],
        method: 'POST',
        retries: 3,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer {token}'
        }
    };
};

export default Node;