import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const WorkspaceDetailsComponent = ({ currentWorkflow,  updateWorkflowHandler}) => {

    const { t } = useTranslation();

    function getFromStorage(key) {
        try {
            const item = window.localStorage.getItem(key);
            return item && item !== 'undefined' ? item : null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    function LabelComponent({ label, value }) {
        return (
            <div className="text-xs text-gray-500 sm:border-gray-200 sm:pr-6 text-xs text-gray-500 sm:border-gray-200 sm:pl-6 sm:border-l">
                <h3 className="text-base font-semibold text-gray-900 text-left">
                    {label}
                </h3>
                <p className="text-base text-gray-700 text-left">
                    {value}
                </p>
            </div>
        );
    }

    const workspaceID = getFromStorage('workspace_id') || '';
    const workspaceName = getFromStorage('workspace_name') || '';

    return (
        <div className="flex justify-between items-start p-4">
            
            {currentWorkflow ? (
                <LabelComponent label={currentWorkflow.type === 'function' ? t('function') : t('automation')} value={currentWorkflow.title} />
            ) : null}

            {workspaceName && workspaceID ? (
                <>
                    <LabelComponent label="Workspace" value={workspaceName} />
                    <LabelComponent label="ID" value={workspaceID} />
                </>
            ) : null}

            {currentWorkflow ? (
                <span className="sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                    <button onClick={(e) => updateWorkflowHandler(e, currentWorkflow.title, currentWorkflow.id)} className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-4 py-2 mt-2 rounded" type="button">
                        {t('save')}
                    </button>
                </span>
            ) : null}
        </div>
    );
};

WorkspaceDetailsComponent.displayName = 'WorkspaceDetailsComponent';

WorkspaceDetailsComponent.propTypes = {
    currentWorkflow: PropTypes.object,
    updateWorkflowHandler: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.string
};

export default WorkspaceDetailsComponent;