import SyncButton from './SyncButton';

const ModalHeader = ({ title, pending, onSync, workflowExecutionId }) => (
    <div className="flex justify-between p-5 border-b border-solid border-slate-200 rounded-t">
        <h3 className="pb-3 uppercase text-xs text-gray-500 ">
            <span className="text-xs font-semibold inline-block py-1 px-2 rounded text-blue-600 bg-blue-200 last:mr-0 mr-1">
                {title}
            </span>
        </h3>

        {!pending && (
            <SyncButton onSync={onSync} workflowExecutionId={workflowExecutionId} />
        )}

    </div>
);

export default ModalHeader;
