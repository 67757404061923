import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import ExpandedComponent from './ExpandedComponent';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGetWorkflowExecutionById } from '../Editor/Hooks/useGetWorkflowExecutionById';
import { useGetExecutionActionByIdAndNode } from '../Editor/Hooks/useGetExecutionActionByIdAndNode';
import { customRowStyles } from '../Editor/js/styles';
import ModalHeader from './ModalHeader';
import { useDatatableColumnsEvent } from '../Editor/Hooks/useDatatableColumnsEvent';
import ModalFooter from './ModalFooter';

const ModalWorkflowInstanceEventsLogs = ({ workflowExecutionId, showModalWorkflowInstanceEventsLogs, setShowModalWorkflowInstanceEventsLogs, setLoading, handleResponseError }) => {

    const [dataComponent, setDataComponent] = useState([]);
    const [pending, setPending] = useState(false);

    const { t } = useTranslation();
    
    const getWorkflowExecutionById = useGetWorkflowExecutionById(setPending, setDataComponent);

    const getExecutionActionByIdAndNode = useGetExecutionActionByIdAndNode(setPending, setDataComponent);

    const columns = useDatatableColumnsEvent(getExecutionActionByIdAndNode, workflowExecutionId);

    useEffect(() => {

        getWorkflowExecutionById(workflowExecutionId);

    }, [getWorkflowExecutionById, workflowExecutionId]);

    return (

        <>

            {showModalWorkflowInstanceEventsLogs ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                       
                        <div className="relative w-auto my-6 mx-auto" style={{ width: '50%' }}>

                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                <ModalHeader title={t('events')} pending={pending} onSync={getWorkflowExecutionById} workflowExecutionId={workflowExecutionId} />

                                <div className="relative p-6" style={{maxHeight: '500px', overflowY: 'auto'}}>
                                    
                                    <DataTable
                                        columns={columns}
                                        data={dataComponent}
                                        progressPending={pending}
                                        expandableRows
                                        expandableRowsComponent={ExpandedComponent}
                                        pagination
                                        customStyles={customRowStyles}  
                                    />

                                </div>

                                <ModalFooter fn={() => setShowModalWorkflowInstanceEventsLogs(false)} label={t('close')} />
                                
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
};

ModalWorkflowInstanceEventsLogs.displayName = 'ModalWorkflowInstanceEventsLogs';

ModalWorkflowInstanceEventsLogs.propTypes = {
    workflowExecutionId: PropTypes.string,
    showModalWorkflowInstanceEventsLogs: PropTypes.bool,
    setShowModalWorkflowInstanceEventsLogs: PropTypes.func,
    currentWorkflow: PropTypes.object,
    setLoading: PropTypes.func,
    handleResponseError: PropTypes.func,
};

export default ModalWorkflowInstanceEventsLogs;