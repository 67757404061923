import PropTypes from 'prop-types';

const NodeInspectorSwitchComponent = ({ nodeId, edges, nodeDetails, handleConnectionLabelChange, handleConnectionLabelBlur, setEditingConnectionId, handleChange, editingConnectionId }) => {
    return (
        <div>
            {
                nodeDetails && nodeDetails.data.nodes > 0 && (
                    <>
                        <h3 className="pb-3 uppercase text-xs text-gray-500">Configuração</h3>
                        <code>
                            <div key="nodes" className="flex justify-between" style={{ marginBottom: '5px', paddingBottom: '5px' }}>
                                <label><b>Nodes: </b> </label>
                                <input className="px-2 input-style py-1 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border-1 shadow outline-none focus:outline-none focus:ring w-full" value={nodeDetails.data.nodes} type="number" max="31" min="2" onChange={(e) => handleChange(e, 'nodes')} style={{ height: '10px', width: '100px', fontSize: '10px', padding: '8px' }} />
                            </div>
                        </code>
                    </>
                )
            }

            {edges.filter(edge => edge.source === nodeId && edge.label).map((edge) => (
                <div key={edge.id} style={{ marginBottom: '15px', borderBottom: '1px solid #ccc', paddingBottom: '15px' }}>
                    <h3 className="pb-3 uppercase text-xs text-gray-500">Node {edge.id} (<b>{edge.description}</b>):</h3>
                    {editingConnectionId === edge.id ? (
                        <input
                            className="px-2 py-1 input-style placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border-1 shadow outline-none focus:outline-none focus:ring w-full"
                            value={edge.label}
                            type="text"
                            onChange={(e) => handleConnectionLabelChange(edge.id, e.target.value)}
                            onBlur={(e) => handleConnectionLabelBlur(edge.id, e.target.value)}
                            style={{ height: '30px' }}
                        />
                    ) : (
                        <div
                            onClick={() => setEditingConnectionId(edge.id)}
                            style={{ cursor: 'pointer', height: '30px' }}
                        >

                            <span className="text-xs font-semibold inline-block py-1 px-2 rounded text-emerald-600 bg-emerald-200 last:mr-0 mr-1">
                                {edge.label}
                            </span>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

NodeInspectorSwitchComponent.displayName = 'NodeInspectorSwitchComponent';

NodeInspectorSwitchComponent.propTypes = {
    nodeId: PropTypes.string.isRequired,
    edges: PropTypes.array.isRequired,
    nodeDetails: PropTypes.object.isRequired,
    handleConnectionLabelChange: PropTypes.func.isRequired,
    handleConnectionLabelBlur: PropTypes.func.isRequired,
    setEditingConnectionId: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    editingConnectionId: PropTypes.string,
};

export default NodeInspectorSwitchComponent;
