import EventNode from './Nodes/EventNode';
import TimerNode from './Nodes/TimerNode';
import BranchNode from './Nodes/BranchNode';
import ForNode from './Nodes/ForNode';
import ForEachNode from './Nodes/ForEachNode';
import DelayNode from './Nodes/DelayNode';
import SwitchNode from './Nodes/SwitchNode';
import WebhookNode from './Nodes/WebhookNode';
import SmsNode from './Nodes/SmsNode';
import SmtpNode from './Nodes/SmtpNode';
import SplitterNode from './Nodes/SplitterNode';
import TransferNode from './Nodes/TransferNode';
import LogNode from './Nodes/LogNode';
import RandomNumberNode from './Nodes/RandomNumberNode';
import RandomNameNode from './Nodes/RandomNameNode';
import PrintNode from './Nodes/PrintNode';
import DateDiffNode from './Nodes/DateDiffNode';
import DatesOfMonthNode from './Nodes/DatesOfMonthNode';
import FindNode from './Nodes/FindNode';
import GetDateNode from './Nodes/GetDateNode';
import HttpRequestNode from './Nodes/HttpRequestNode';
import IsWeekendDayNode from './Nodes/IsWeekendDayNode';
import LastDayCurrentMonthNode from './Nodes/LastDayCurrentMonthNode';
import UppercaseNode from './Nodes/UppercaseNode';
import LowercaseNode from './Nodes/LowercaseNode';
import BreakNode from './Nodes/BreakNode';
import DataMapNode from './Nodes/DataMapNode';
import SaveVariableNode from './Nodes/SaveVariableNode';
import ScheduleNode from './Nodes/ScheduleNode';
import ApiNode from './Nodes/ApiNode';
import MarketplaceNode from './Nodes/MarketplaceNode';
import UrlEncodeNode from './Nodes/UrlEncodeNode';
import UrlDecodeNode from './Nodes/UrlDecodeNode';
import MapperNode from './Nodes/MapperNode';
import JoinNode from './Nodes/JoinNode';
import ConcatNode from './Nodes/ConcatNode';
import SubtractDate from './Nodes/SubtractDateNode';
import UcFirst from './Nodes/UcFirstNode';
import VariableNode from './Nodes/VariableNode';
import BlueprintNode from './Nodes/BlueprintNode';
import InputNode from './Nodes/InputNode';
import OutputNode from './Nodes/OutputNode';
import MathExpressionNode from './Nodes/MathExpressionNode';
import UnsetNode from './Nodes/UnsetNode';
import ChatGPTNode from './Nodes/ChatGPTNode';

const DataComponent = ({ type }) => {
    
    const nodeMapping = {
        'event': EventNode,
        'timer': TimerNode,
        'schedule': ScheduleNode,
        'api': ApiNode,
        'branch': BranchNode,
        'for': ForNode,
        'for_each': ForEachNode,
        'delay': DelayNode,
        'switch': SwitchNode,
        'webhook': WebhookNode,
        'sms': SmsNode,
        'smtp': SmtpNode,
        'splitter': SplitterNode,
        'transfer': TransferNode,
        'log': LogNode,
        'random_number': RandomNumberNode,
        'random_name': RandomNameNode,
        'print': PrintNode,
        'date_diff': DateDiffNode,
        'dates_of_month': DatesOfMonthNode,
        'find': FindNode,
        'get_date': GetDateNode,
        'http_request': HttpRequestNode,
        'is_weekend_day': IsWeekendDayNode,
        'last_day_current_month': LastDayCurrentMonthNode,
        'uppercase': UppercaseNode,
        'lowercase': LowercaseNode,
        'datamap': DataMapNode,
        'break': BreakNode,
        'save_variable': SaveVariableNode,
        'marketplace': MarketplaceNode,
        'url_encode': UrlEncodeNode,
        'url_decode': UrlDecodeNode,
        'mapper': MapperNode,
        'join': JoinNode,
        'concat': ConcatNode,
        'subtract_date': SubtractDate,
        'ucfirst': UcFirst,
        'variable': VariableNode,
        'blueprint': BlueprintNode,
        'input': InputNode,
        'output': OutputNode,
        'math_expression': MathExpressionNode,
        'unset': UnsetNode,
        'chatgpt': ChatGPTNode,
    };

    let nodeType = 'action';

    const data = nodeMapping[type]();

    if (type === 'event' || type === 'timer' || type === 'schedule' || type === 'api') {
        nodeType = 'trigger';
    }

    if (type === 'branch') {
        nodeType = 'branch';
    }

    if (type === 'switch') {
        nodeType = 'switch';
    }

    if (type === 'variable') {
        nodeType = 'variable';
    }

    if (type === 'input') {
        nodeType = 'input_function';
    }

    if (type === 'output') {
        nodeType = 'output_function';
    }

    if (type === 'blueprint') {
        nodeType = 'blueprint';
    }

    data.type = nodeType;
    data.custom_params = [];
    
    return data;
};

export default DataComponent;

