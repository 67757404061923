import { v4 as uuidv4 } from 'uuid';

import uuid62 from 'uuid62';

let nodeId = 0;

let edgeId = 0;

export const getNodeId = () => `${nodeId++}`;

export const setMaxIdNode = (id) => {
    nodeId = id;
}

export const setMaxIdEdge = (id) => {
    edgeId = id;
}

export const getEdgeId = () => `${edgeId++}`;

export function getParamData(node, paramKey) {
    return paramKey === 'custom_params' ? node.data.custom_params : node.data.params;
}

export function getHandleDetails(handleString) {
    const parts = handleString.split('-');
    return {
        id: parts[1],
        key: parts[4],
        paramIndex: parts[5],
    };
}

export function findNodeById(nodes, nodeId) {
    return nodes.find(n => n.id === nodeId);
}

export function findEdgeById(edges, edgeId) {
    return edges.find(e => e.id === edgeId);
}

export function createNewNode(data, position) {
    return {
        id: getNodeId(),
        type: data.type,
        position: position,
        data: data
    };
}

export function getEventType(event) {
    return event.dataTransfer.getData('application/nodeType');
}

export function getEventData(event) {
    return event.dataTransfer.getData('application/nodeData');
}

export function handleTriggerType(data) {
    if (data.bpFunc === 'Api') {
        let uuid = uuidv4();
        const uuidToBase62 = uuid62.encode(uuid);
        data.params[0].value = uuidToBase62;
    }
}

export function isValidFlow(flow) {

    if (flow.edges.length === 0) {
        console.log('Flow validation failed: Please connect edges.');
        return false;
    }

    console.log(`Flow contains ${flow.nodes.length} nodes and ${flow.edges.length} edges.`);

    return true;
}

export const updateNodeData = (node, newData) => {
    return {
        ...node,
        data: { ...node.data, ...newData },
    };
};