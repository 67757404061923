import { useCallback } from 'react';
import { fetchGetInstalledAppsActions } from '../../../Services/WorkflowService';

export const useGetInstalledAppsActions = (setInstalledAppsActions) => useCallback(async () => {

    try {

        const responseData = await fetchGetInstalledAppsActions();

        setInstalledAppsActions(responseData);

    } catch (error) {
        console.log(error);
    }

}, [setInstalledAppsActions]);


