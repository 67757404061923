import { useTranslation } from 'react-i18next';

export const useDateFormat = () => {
    
    const { t } = useTranslation();

    return (dateString) => {
        const months = [
            'january', 'february', 'march', 'april', 'may', 'june',
            'july', 'august', 'september', 'october', 'november', 'december'
        ];
        
        const date = new Date(dateString);
        const monthIndex = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        const month = t(monthIndex);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
      
        const formattedDate = t('date_format', { day, month, year, hours, minutes });
        return formattedDate;
    };
};
