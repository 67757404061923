import React, { useState, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import DisplayTypeComponent from './DisplayTypeComponent';
import EditableDisplayName from './EditableDisplayName';
import PropTypes from 'prop-types';

function OutputDisplay({ dataForms, params, nodeId, nodeType, fieldType }) {
    
    const [outputParams, setOutputParams] = useState([]);

    const { bpFunc } = dataForms;

    useEffect(() => {

        let paramsName = {};

        params.forEach((param, index) => {
            
            if (param.name === 'auth') {
                return;
            }

            if (!param.previousName) {
                param.previousName = param.name;
            }

            param.index = index;

            if (param.direction === 'ParamOutput' && !param.value && fieldType !== 'custom_params') {
                param.value = `{${param.name}}`;
            }

            if (param.previousName !== param.name) {
                delete paramsName[param.previousName];
                param.previousName = param.name;
            }

            if (!paramsName[param.name]) {
                paramsName[param.name] = param;
            } else {
                console.error(`Nome do parâmetro '${param.name}' já está em uso.`);
                return;
            }

            params[index] = param;
        });

        setOutputParams(Object.values(paramsName));


    }, [fieldType, params, setOutputParams]);

    const updateParamName = (index, newValue) => {
        params[index].name = newValue;
    };

    const generateNodeId = (bpFunc) => {
        return `${nodeId}-${bpFunc}-${nodeType}-${fieldType}`.replace(/\s+/g, '-').toLowerCase();
    };

    const baseId = generateNodeId(bpFunc);

    if (outputParams.length === 0) {
        return null;
    }
    
    return (

        <div className='pt-3 bg-output-node'>

            <h1 className='text-title pb-3'>Saída:</h1>

            {outputParams.filter(param => param.name !== 'callback_url' && param.name !== 'callback_app_id').map((param) => {

                const handleId = `handleId-${baseId}-${param.index}`;

                return (

                    <React.Fragment key={handleId}>

                        <div key={`div-${baseId}`} className="flex flex-col justify-between margin-fragment" >

                            <div>
                                <div className="flex justify-between">

                                    <EditableDisplayName
                                        key={param.index}
                                        index={param.index}
                                        param={param}
                                        onUpdate={(index, value) => updateParamName(param.index, value)}
                                    />

                                    <span className='text-value'><DisplayTypeComponent key={`display-${baseId}`} type={param.type} /></span>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                    <Handle
                                        type="source"
                                        className="react-flow__handle--out"
                                        key={handleId}
                                        position={Position.Right}
                                        id={handleId}
                                        style={{
                                            background: '#555',
                                            position: 'relative',
                                            right: '-20px',
                                            top: '-7px'
                                        }}
                                    />
                                </div>

                            </div>

                        </div>

                    </React.Fragment>

                );

            })}

        </div>
    );
}

OutputDisplay.propTypes = {
    dataForms: PropTypes.shape({
        bpFunc: PropTypes.string.isRequired
    }).isRequired,
    params: PropTypes.array.isRequired,
    nodeId: PropTypes.string.isRequired,
    nodeType: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired
};

export default OutputDisplay;
