import { useCallback } from 'react';
import { useRetrieveApikeys } from './useGetApikeys';
import { updateAuthOptions } from '../../../Services/AuthService';

export const useHandleNodeClick = (setNodes, setNodeDetails) => {

    const retrieveApikeys = useRetrieveApikeys();

    const updateNodeWithOptions = useCallback((node, options) => {
        const updatedNode = updateAuthOptions({ ...node }, options);
        setNodes(prevNodes => prevNodes.map(n => n.id === updatedNode.id ? updatedNode : n));
    }, [setNodes]);

    const handleApiNodeClick = useCallback((node) => {
        retrieveApikeys().then(apikeys => {
            const apiOptions = apikeys.map(apikey => ({ value: apikey.id, label: apikey.name }));
            const options = [{ value: '', label: 'Selecione uma chave de API' }, ...apiOptions];
            updateNodeWithOptions(node, options);
        }).catch(error => {
            console.error('Erro ao obter as chaves da API:', error);
        });
    }, [retrieveApikeys, updateNodeWithOptions]);

    return useCallback((event, node) => {
        if (node.data.bpFunc === 'Api') {
            handleApiNodeClick(node);
        }

        setNodeDetails(node);

    }, [handleApiNodeClick, setNodeDetails]);
};
