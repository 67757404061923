const Node = () => {
    return {
        bpFunc: 'DateDiff',

        title: 'DateDiff',
        label: 'DateDiff',
        icon: '/icon_action.png',
        leftHandler: true,
        params: [
            {
                name: 'start_date',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'end_date',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'diff',
                value: '',
                type: 'integer',
                required: true,
				direction: 'ParamOutput'
            },
        ]
    };
};

export default Node;