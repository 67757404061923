import Breadcrumb from './Breadcrumb'; 

const PageHeader = ({ title, breadcrumbItems, rightContent }) => (
    <div className="flex justify-between items-center">
        <span className="mt-10 mb-10 text-4xl font-semibold leading-6 ml-10">
            {title}
            {breadcrumbItems && <Breadcrumb items={breadcrumbItems} />}
        </span>
        {rightContent}
    </div>
);

export default PageHeader;
