const Node = () => {
    return {
        bpFunc: 'Schedule',

        title: 'Schedule',
        label: 'Schedule',
        icon: '/icon_schedule.png',
        leftHandler: false,
        params: [
            {
                name: 'frequency',
                alias: 'Frequência',
                value: 'daily',
                type: 'select',
                options : [
                    { 
                        value: 'daily', label: 'Diariamente' 
                    },
                    {
                        value: 'minutes', label: 'Intervalo de tempo'
                    },
                ],
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'scheduled_time',
                alias: 'Horário ou Intervalo',
                value: '',
                placeholder: 'Ex: 08:00 ou 5m',
                type: 'string',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'current_time',
                alias: 'Horário da execução',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            },
        ]
    };
};

export default Node;