import { useCallback } from 'react';
import { useUpdatePreloadingData } from './useUpdatePreloadingData';
import { findEdgeById, findNodeById, getHandleDetails} from '../Utils';

export const useHandleEdgesChange = (edges, nodes, setNodes, updateNodeParamValue, onEdgesChange) => {

    const updatePreloadingData = useUpdatePreloadingData(setNodes);

    return useCallback((changes) => {

        changes.forEach(change => {

            if (change.type === 'remove' && change.id) {

                const targetHandle = findEdgeById(edges, change.id);

                const targetDetails = getHandleDetails(targetHandle.targetHandle);

                const targetNode = findNodeById(nodes, targetHandle.target);

                if (!targetNode) return;

                updateNodeParamValue(targetHandle.target, targetDetails.key, parseInt(targetDetails.paramIndex), '');

                updatePreloadingData(targetHandle.target, targetHandle.source, 'delete');

            }
        });

        onEdgesChange(changes);

    }, [edges, nodes, updatePreloadingData, updateNodeParamValue, onEdgesChange]);

};