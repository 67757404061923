import { useCallback } from 'react';

const updatePreloadingData = (sourceIdInt, nodeData, action) => {
    switch (action) {
        case 'add':
            return addSourceId(nodeData, sourceIdInt);
        case 'delete':
            return deleteSourceId(nodeData, sourceIdInt);
        default:
            return nodeData['preloading_data'];
    }
};

const addSourceId = (nodeData, sourceIdInt) => {
    
    const preloadingData = Array.isArray(nodeData['preloading_data']) ? [...nodeData['preloading_data']] : [];
    
    if (!preloadingData.includes(sourceIdInt)) {
        preloadingData.push(sourceIdInt);
    }
    
    return preloadingData;
};

const deleteSourceId = (nodeData, sourceIdInt) => {
    return (nodeData['preloading_data'] || []).filter(id => id !== sourceIdInt);
};

export const useUpdatePreloadingData = (setNodes) => useCallback((nodeId, sourceId, action) => {

    const sourceIdInt = parseInt(sourceId);

    setNodes((currentNodes) => currentNodes.map((node) => {
       
        if (node.id === nodeId) {

            const updatedNodeData = {
                ...node.data,
                'preloading_data': updatePreloadingData(sourceIdInt, node.data, action),
            };

            return {
                ...node,
                data: updatedNodeData,
            };
        }

        return node;

    }));

}, [setNodes]);