import PropTypes from 'prop-types';

const NodeInspectorCustomParamsComponent = ({ nodeDetails, handleAddField, handleChange, handleRemoveField }) => {

    return (
        <div>
            {
                nodeDetails && nodeDetails.data.set_custom_params && (
                    <>
                        <div className="flex justify-between" >
                            <label><b><h3 className="pb-3 uppercase text-xs text-gray-500">Paramêtros customizados </h3></b> </label>
                            <span><button onClick={handleAddField}>➕</button></span>
                        </div>

                        {nodeDetails && nodeDetails.data.custom_params && nodeDetails.data.custom_params.map((param, index) => (

                            <div key={index} style={{ backgroundColor: '#ecf0f1', padding: '5px', borderRadius: '5px', marginBottom: '10px', border: '1px solid #ccc', paddingBottom: '15px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ width: '90%' }}>
                                        <input
                                            className="px-2 py-1 mt-2 input-style placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border-1 shadow outline-none focus:outline-none focus:ring w-full"
                                            placeholder="new param"
                                            value={param.name}
                                            type="text"
                                            onChange={(e) => handleChange(e, { type: 'custom', key: index, field: 'name' })}
                                            style={{ height: '30px', marginBottom: '5px', padding: '5px' }}
                                        />

                                        <input
                                            className="px-2 py-1 input-style placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border-1 shadow outline-none focus:outline-none focus:ring w-full"
                                            placeholder="value"
                                            value={param.value || ''}
                                            type="text"
                                            onChange={(e) => handleChange(e, { type: 'custom', key: index, field: 'value' })}
                                            style={{ height: '30px', marginBottom: '5px', padding: '5px' }}
                                        />
                                        <select
                                            value={param.type}
                                            className="input-style"
                                            onChange={(e) => handleChange(e, { type: 'custom', key: index, field: 'type' })}
                                            style={{ height: '30px', width: '100%', fontSize: '12px', padding: '5px' }}
                                        >
                                            <option value="string">String</option>
                                            <option value="integer">Integer</option>
                                            <option value="boolean">Boolean</option>
                                            <option value="float">Float</option>
                                        </select>
                                    </div>
                                    <button style={{ marginTop: '5px', right: '0', marginBottom: '10px', width: '5%' }} onClick={() => handleRemoveField(index)}>🗑️</button>
                                </div>
                            </div>
                        ))}

                    </>
                )
            }
        </div>
    );
};

NodeInspectorCustomParamsComponent.displayName = 'NodeInspectorCustomParamsComponent';

NodeInspectorCustomParamsComponent.propTypes = {
    nodeDetails: PropTypes.object.isRequired,
    handleAddField: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleRemoveField: PropTypes.func.isRequired
};

export default NodeInspectorCustomParamsComponent;
