import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { fetchGetInstalledApps } from '../../../Services/WorkflowService';

export const useGetInstalledApps = (setLoading, setInstalledApps) => useCallback(async () => {

    setLoading(true);

    try {

        const responseData = await fetchGetInstalledApps();

        setInstalledApps(responseData);

    } catch (error) {

        handleResponseError(error);

    } finally {

        setLoading(false);
    }

}, [setLoading, setInstalledApps]);


