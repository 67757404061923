import { useState, useEffect, memo, useCallback } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import InputDisplay from '../Components/InputDisplay';
import OutputDisplay from '../Components/OutputDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useGetBlueprintNodeByWorkflowId } from './Hooks/useGetBlueprintNodeByWorkflowId';

const BlueprintNode = memo(({ data, id, type }) => {

    const reactFlowInstance = useReactFlow();

    const navigate = useNavigate();

    const [inputParams, setInputParams] = useState([]);
    const [outputParams, setOutputParams] = useState([]);

    const updateNodeDetails = useCallback((newDetails) => {

        if (!reactFlowInstance || !newDetails) return;

        reactFlowInstance.setNodes((prevNodes) =>
            prevNodes.map(node => node.id === newDetails.id
                ? { ...node, data: newDetails.data }
                : node
            )
        );

    }, [reactFlowInstance]);

    const getBlueprintNodeByWorkflowId = useGetBlueprintNodeByWorkflowId(data, setInputParams, setOutputParams, updateNodeDetails);

    const handleNodeEditBlueprint = useCallback((event, node) => {
        
        const path = `/workflow/${node.workflow_id}`;
    
        navigate(path);
    
    }, [navigate]);

    useEffect(() => {

        if (data.workflow_id) {
            getBlueprintNodeByWorkflowId(data.workflow_id);
        }

    }, [data, getBlueprintNodeByWorkflowId]);

    return (

        <div className="bp-window">

            <div className="bp-label gradient-bg-blueprint">
                Function
            </div>

            <div className="bp-icon" style={{ top: '-25px', right: '-1px', width: '20px', height: '20px', padding: '10px' }}>    
                <label onClick={(e) => handleNodeEditBlueprint(e, data)}>
                    <FontAwesomeIcon icon={faPen} className="icon-edit" style={{ fontSize: '10px' }} />
                </label>
            </div>

            <div className="bp-body">
                
                <div className='text-title' style={{ backgroundColor: '#f5f5f5', padding: '5px', borderRadius: '5px', marginBottom: '10px', textAlign: 'center' }}>
                    {data.label}
                </div>

                {inputParams && inputParams.length > 0 && (
                    <InputDisplay dataForms={data}  params={inputParams} nodeId={id} nodeType={type} fieldType="display_params" />
                )}

                {outputParams && outputParams.length > 0 && (
                    <OutputDisplay dataForms={data} params={outputParams} nodeId={id} nodeType={type} fieldType="display_params"/>
                )}

            </div>

            {data.leftHandler && (
                <Handle type="target" className="react-flow__handle--in" key={`in-${id}`} position={Position.Left} id="in" style={{
                    background: '#555',
                    position: 'absolute',
                    top: '10px',
                    left: '-10px',
                }} />
            )}

            {data.rightHandler && (
                <Handle type="source" position={Position.Right} key={`out-${id}`} className="react-flow__handle--out" id="out" style={{
                    background: '#555',
                    position: 'absolute',
                    top: '10px',
                    right: '-10px',
                }} />
            )}

        </div>
    );
});

BlueprintNode.displayName = 'BlueprintNode';

BlueprintNode.propTypes = {
    data: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default BlueprintNode;