import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { fetchAllWorkflowIntancesByWorkflowId } from '../../../Services/WorkflowService';

export const useGetWorkflowIntancesByWorkflowId = (setPending, setLoading, setDataComponent) => useCallback(async (currentWorkflowId) => {

    setPending(true);

    try {

        const responseData = await fetchAllWorkflowIntancesByWorkflowId(currentWorkflowId);

        setDataComponent(responseData);

        setLoading(false);

    } catch (error) {

        handleResponseError(error);

    } finally {

        setPending(false);
        setLoading(false);

    }

}, [setPending, setLoading, setDataComponent]);