import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { fetchAllWorkflows } from '../../../Services/WorkflowService';

export const useGetWorkflows = (setPending, setWorkflowsComponent) => useCallback(async () => {

    setPending(true);

    try {

        const data = await fetchAllWorkflows();

        setWorkflowsComponent(data);

    } catch (error) {

        handleResponseError(error);

    } finally {

        setPending(false);

    }

}, [setPending, setWorkflowsComponent]);

export const useRetrieveWorkflows = (setLoading) => useCallback(async () => {

    setLoading(true);

    try {

        await fetchAllWorkflows();

    } catch (error) {

        handleResponseError(error);

    } finally {

        setLoading(false);

    }

}, [setLoading]);

export const useGetWorkflowsTypeFunction = (action_list, setActionList) => {

    return useCallback(async (currentWorkflow) => {

        if (!currentWorkflow) {
            return;
        }

        const workflowID = currentWorkflow.id;

        const addedIds = new Set();

        try {

            const data = await fetchAllWorkflows('function');

            if (data && data.length > 0) {

                for (const item of data) {
                    
                    if (item.id === workflowID) {
                        continue;
                    }

                    if (!addedIds.has(item.id)) {
                        action_list.push({ id: item.id, label: item.title, image: '', function: true});
                        addedIds.add(item.id);
                    }
                }
            }

            const sortedActionList = [...action_list].sort((a, b) => a.label.localeCompare(b.label));

            setActionList(sortedActionList);
            

        } catch (error) {
            console.error(error);
        }

    }, [action_list, setActionList]);

};