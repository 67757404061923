import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { fetchExecutionActionByIdAndNode } from '../../../Services/WorkflowService';
import { useGetWorkflowExecutionById } from './useGetWorkflowExecutionById';

export const useGetExecutionActionByIdAndNode = (setPending, setDataComponent) => {

    const getWorkflowExecutionById = useGetWorkflowExecutionById(setPending, setDataComponent);

    return useCallback(async (workflowExecutionId, node) => {

        setPending(true);

        try {

            await fetchExecutionActionByIdAndNode(workflowExecutionId, node);

            getWorkflowExecutionById(workflowExecutionId);

        } catch (error) {

            handleResponseError(error);

        } finally {

            setPending(false);

        }

    }, [setPending, getWorkflowExecutionById]);

};