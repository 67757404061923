import { memo } from 'react';
import { Handle, Position } from 'reactflow';
import InputDisplay from '../Components/InputDisplay';
import OutputDisplay from '../Components/OutputDisplay';
import NodeIcon from '../Components/NodeIcon';
import PropTypes from 'prop-types';

const TriggerNode = memo(({ data, id, type }) => {

    const inputParams = data.params.filter(param => param.direction === 'ParamInput' && !param.hidden);
    
    const customParams = data.custom_params.filter(param => param.name !== '');
    
    return (

        <div className="bp-window">
        
            <div className="bp-label">{data.bp_func_alias ? data.bp_func_alias : data.bpFunc}</div>
        
            <NodeIcon nodeIcon={data.icon}/>
        
            <div className="bp-body">

                <div className='text-title' style={{ backgroundColor: '#f5f5f5', padding: '5px', borderRadius: '5px', marginBottom: '10px', textAlign: 'center' }}>
                    {data.title}
                </div>
                
                <InputDisplay dataForms={data} params={inputParams} nodeId={id} nodeType={type} fieldType="input_params"/>

                <OutputDisplay dataForms={data} params={data.params} nodeId={id} nodeType={type} fieldType="output_params"/>

                <OutputDisplay dataForms={data} params={customParams} nodeId={id} nodeType={type} fieldType="custom_params"/>

            </div>
        
            <Handle type="source" position={Position.Right} className="react-flow__handle--out" id="out" />
        
        </div>
    );
});

TriggerNode.displayName = 'TriggerNode';

TriggerNode.propTypes = {
    data: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default TriggerNode;