import { useCallback} from 'react';

export const useRenderButtons = (onDragStart) => useCallback((list) => {
            
    return list.map(action => (
        <button
          key={action.id}
          className={`dndnode flex gap-1 items-center justify-items-center justify-center ${action.disabled ? 'disabled' : ''}`}
          onDragStart={(event) => onDragStart(event, action)}
          draggable={!action.disabled}
        >
          {action.image && <img alt="icon" style={{ height: '30px', width: '30px' }} src={action.image} />}
          {action.label}
        </button>
    ));

}, [onDragStart]);