import { useCallback } from 'react';

import { addEdge } from 'reactflow';
import { findNodeById, getHandleDetails, getParamData, getEdgeId } from '../Utils';
import { useUpdatePreloadingData } from './useUpdatePreloadingData';

export const useOnConnect = (edges, nodes, setNodes, updateNodeParamValue, setEdges) => {

    const updatePreloadingData = useUpdatePreloadingData(setNodes);

    return useCallback((params) => {
    
        const sourceNode = nodes.find((node) => node.id === params.source);
        const targetNode = nodes.find((node) => node.id === params.target);

        const sourceHandleOk = params.sourceHandle && params.sourceHandle.startsWith('handleId');
        const targetHandleOk = params.targetHandle && params.targetHandle.startsWith('handleId');

        if (sourceHandleOk || targetHandleOk) {

            if (!sourceHandleOk || !targetHandleOk) return;

            const edgeExists = edges.some(edge => edge.targetHandle === params.targetHandle);

            if (edgeExists) return;

            const sourceDetails = getHandleDetails(params.sourceHandle);

            const sourceHandleNode = findNodeById(nodes, sourceDetails.id);

            if (sourceHandleNode) {

                let bpNodeOutput = null;

                if (sourceHandleNode.data.blueprint) {
                    
                    for (const bpNode of sourceHandleNode.data.params) {
                        if (bpNode.direction === 'ParamOutput') {
                            bpNodeOutput = bpNode;
                        }
                    }
                }

                let sourceParam = '';

                if (!bpNodeOutput) {

                    let paramSourceData = getParamData(sourceHandleNode, sourceDetails.key);

                    if (paramSourceData.length === 0) return;

                    const nodeIndexParamSource = paramSourceData[sourceDetails.paramIndex];

                    if (!nodeIndexParamSource || (nodeIndexParamSource && !nodeIndexParamSource.name)) return;

                    sourceParam = `{${nodeIndexParamSource.name}}`;

                } else {
                    sourceParam = `{${bpNodeOutput.name}}`;
                }

                const targetDetails = getHandleDetails(params.targetHandle);

                const targetHandleNode = findNodeById(nodes, targetDetails.id);

                if (targetHandleNode) {
                    
                    updateNodeParamValue(targetNode.id, targetDetails.key, parseInt(targetDetails.paramIndex), sourceParam);

                    if (sourceHandleNode.data.preloader) {
                        updatePreloadingData(targetNode.id, sourceNode.id, 'add');
                    }

                    params['animated'] = true;
                }
            }
        }

        if (sourceNode && targetNode && sourceNode.type === 'switch') {
            const label = targetNode.data.title;
            params['label'] = label;
            params['description'] = label;
        }

        params['id'] = getEdgeId();

        setEdges((eds) => addEdge(params, eds));

    }, [edges, nodes, updatePreloadingData, updateNodeParamValue, setEdges]);

};