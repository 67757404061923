const Node = () => {
    return {

        bpFunc: 'SaveVariable',

        title: 'SaveVariable',
        label: 'SaveVariable',
        icon: '/icon_action.png',
        leftHandler: true,
        params: [],
        set_custom_params: true
    };
};

export default Node;