import { memo, useState, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import ParamsDisplayComponent from '../Components/ParamsDisplayComponent';
import PropTypes from 'prop-types';

const OutputNode = memo(({ data, id, type }) => {

    const { custom_params, leftHandler, rightHandler, bp_func_alias, bpFunc, icon, title } = data;

    const [outputParams, setOutputParams] = useState([]);

    const updateParamName = (index, newValue) => {
        custom_params[index].name = newValue;
    };

    const generateNodeId = (bpFunc) => {
        return `${id}-${bpFunc}-${type}-custom_params`.replace(/\s+/g, '-').toLowerCase();
    };

    const baseId = generateNodeId(bpFunc);

    const nodeTargetHandle = leftHandler ? (
        <Handle type="target" className="react-flow__handle--in" key={`in-${id}`} position={Position.Left} id="in" />
    ) : null;

    const nodeSourceHandle = (rightHandler === undefined || rightHandler) ? (
        <Handle type="source" position={Position.Right} key={`out-${id}`} className="react-flow__handle--out" id="out" />
    ) : null;

    useEffect(() => {

        setOutputParams(custom_params);

    }, [custom_params]);

    return (

        <div className="bp-window">

            <div className="bp-label gradient-bg-output">{bp_func_alias || bpFunc}</div>

            <ParamsDisplayComponent
                outputParams={outputParams}
                baseId={baseId}
                updateParamName={updateParamName}
                targetHandle={nodeTargetHandle}
                sourceHandle={nodeSourceHandle}
                icon={icon}
                title={title}
                handle='in'
            />

        </div>
    );
});

OutputNode.displayName = 'OutputNode';

OutputNode.propTypes = {
    data: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default OutputNode;

