import { useCallback } from 'react';
import DataComponent from '../DataComponent';
import { createNewNode } from '../Utils';

export const useWorkflowNodeInjector = (setNodes) => {
    
    const addNodeIfNeeded = useCallback((type, position, conditionKey) => {
            
            const nodeData = DataComponent({ type });
            
            nodeData[conditionKey] = true;
            
            const newNode = createNewNode(nodeData, position);
            
            setNodes(nds => [...nds, newNode]);
        
    }, [setNodes]);

    const addNodesIfNeededForBlueprintFunction = useCallback(() => {
            addNodeIfNeeded('input', { x: 150, y: 150 }, 'input');
            addNodeIfNeeded('output', { x: 900, y: 600 }, 'output');
    }, [addNodeIfNeeded]);

    return { addNodesIfNeededForBlueprintFunction };
};