import { handleResponseError } from '../../../Services/AuthService';
import { updateWorkflow } from '../../../Services/WorkflowService';

export const useHandlerUpdateWorkflow = (setLoading, setShowModal, getWorkflows, isPublic, setIsPublic) => async (e) => {

    e.preventDefault();

    setShowModal(false);
    setLoading(true);

    const id = e.target.id.value;

    const title = e.target.title.value;

    let type = '';

    if (e.target.type !== undefined) {
        type = e.target.type.value;
    }

    let is_public = isPublic;

    const data = { title, type, is_public };

    try {

        await updateWorkflow(id, data);

        setIsPublic(false);
        
        getWorkflows();

    } catch (error) {

        handleResponseError(error);

    } finally {

        setLoading(false);

    }

};