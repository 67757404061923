import { memo } from 'react';
import { Handle, Position } from 'reactflow';
import InputDisplay from '../Components/InputDisplay';
import NodeIcon from '../Components/NodeIcon';
import PropTypes from 'prop-types';

const SwitchNode = memo(({ data, id, type }) => {
    
    const inputParams = data.params.filter(param => param.direction === 'ParamInput' && !param.hidden);

    const generateNodeId = (bpFunc) => {
        return `${bpFunc}`.replace(/\s+/g, '-').toLocaleLowerCase();
    };

    return (

        <div className="bp-window">

            <div className="bp-label">{data.bp_func_alias ? data.bp_func_alias : data.bpFunc}</div>
            
            <NodeIcon nodeIcon={data.icon}/>

            <div className="bp-body">
             
                <div className='text-title' style={{ backgroundColor: '#f5f5f5', padding: '5px', borderRadius: '5px', marginBottom: '10px', textAlign: 'center' }}>
                    {data.title}
                </div>
             
                {inputParams.length > 0 && <InputDisplay dataForms={data} params={inputParams} nodeId={id} nodeType={type} fieldType="input_params"/>}
        
            </div>

            <Handle type="target" className="react-flow__handle--in" position={Position.Left} id="in" />

            {Array.from({ length: data.nodes }).map((_, index) => {
                
                const baseId = generateNodeId(data.bpFunc, data.title);
                const handleId = `${baseId}-${index + 1}`;
                
                return (
                    <Handle
                        type="source"
                        key={handleId}
                        position={Position.Right}
                        id={handleId}
                        style={{ 
                            background: '#555',
                            top: `${(index + 2) * 100 / (data.nodes + 1)}%`
                        }}
                    />
                );
            })}

        </div>

    );
});

SwitchNode.displayName = 'SwitchNode';

SwitchNode.propTypes = {
    data: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default SwitchNode;