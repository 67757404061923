import { useTranslation } from 'react-i18next';
import { useMoneyFormat } from './useMoneyFormat';

const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const dateString = date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const timeString = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    return `${dateString} às ${timeString}`;
};

const formatExecutionTime = (microseconds) => {
    return `${(microseconds / 1000000).toFixed(2)}s`;
};

export const useDatatableColumnsInvoice = () => {

    const { t } = useTranslation();
    
    const moneyFormat = useMoneyFormat();

    const columns = [
        {
            name: <span className="text-base">{t('code')}</span>,
            selector: row => row.id,
            grow: 4,
            sortable: true,
        },
        {
            name: <span className="text-base">Workflow</span>,
            selector: row => row.title,
            grow: 4,
            sortable: true,
        },
        {
            name: <span className="text-base">{t('total_events_processed')}</span>,
            selector: row => row.events,
            grow: 4,
            sortable: true,
        },
        {
            name: <span className="text-base">{t('total_execution_time')}</span>,
            selector: row => formatExecutionTime(row.microseconds),
            grow: 4,
            sortable: true,
        },
        {
            name: <span className="text-base">{t('value')}</span>,
            selector: row => moneyFormat(row.amount_cents),
            grow: 2,
            sortable: true,
        },
        {
            name: <span className="text-base">{t('last_execution')}</span>,
            selector: row => formatDate(row.last_execution_time),
            grow: 2,
            sortable: true,
        },
    ];

    return columns;
};