import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { runWorkflow } from '../../../Services/WorkflowService';
import { useSetFlow } from './useSetFlow';

export const useRunWorkflow = ( reactFlowInstance, currentWorkflow, setLoading, isValidFlow, setWorkflowExecutionId, setShowEvents, setAlertMessage, setShowAlert ) => {

    const setFlow = useSetFlow(reactFlowInstance);

    return useCallback(async (event, step, parseJson) => {
       
        event.preventDefault();
       
        setLoading(true);

        if (!reactFlowInstance) return;

        const flow = reactFlowInstance.toObject();

        if (!isValidFlow(flow)) {
            setLoading(false);
            return;
        }

        setFlow();

        const payloadBody = JSON.parse(JSON.stringify(flow));
        if (!currentWorkflow) return;

        const payload = {
            workflow_id: currentWorkflow.id,
            step: step,
            blueprint: payloadBody,
            payload: parseJson
        };

        try {
            const responseData = await runWorkflow(payload);
            setLoading(false);
            setWorkflowExecutionId(responseData.id);
            setShowEvents(true);
            setAlertMessage(responseData.message);
            setShowAlert(true);
        } catch (error) {
            handleResponseError(error);
        }

    }, [ reactFlowInstance, currentWorkflow, setLoading, isValidFlow, setWorkflowExecutionId, setShowEvents, setAlertMessage, setShowAlert, setFlow ]);
};
