import PropTypes from 'prop-types';

function NodeIcon({ nodeIcon }) {

    return (
        <div className="bp-icon">
            <span><img src={nodeIcon} alt="icon" /></span>
        </div>
    );
}

NodeIcon.displayName = 'NodeIcon';

NodeIcon.propTypes = {
    nodeIcon: PropTypes.string.isRequired
};

export default NodeIcon;