const Node = () => {
    return {
        bpFunc: 'Event',

        title: 'Event',
        label: 'Event',
        icon: '/icon_event.png',
        leftHandler: false,
        params: [
            {
                name: 'event',
                alias: 'Nome do Evento',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'payload',
                alias: 'Payload',
                value: '',
                type: 'textarea',
                required: true,
                direction: 'ParamInput',
                hidden: true,
            }
        ]
    };
};

export default Node;