import { useCallback } from 'react';

export const useHandleMarketplaceType = (installedApps) => useCallback(async (data, event) => {

    const external = event.dataTransfer.getData('application/marketplace_app');

    if (!installedApps) {
        return;
    }

    const app = JSON.parse(external);
    
    const appMarketplace = installedApps.find(i => i.name === app.name);

    data.bp_func_alias = appMarketplace.title;
    data.name = appMarketplace.name;
    data.title = appMarketplace.title;
    data.label = appMarketplace.label;
    data.icon = appMarketplace.icon;

    data.params = appMarketplace.parameters.map(param => {
        return {
            direction: param.direction,
            name: param.name,
            required: param.required,
            type: param.type,
            value: param.value
        };
    });

    let callbackParam = [{
        name: 'callback_url',
        value: appMarketplace.url,
        type: 'string',
        required: true,
        hidden: true,
        direction: 'ParamInput'
    }, {
        name: 'callback_app_id',
        value: appMarketplace.id,
        type: 'string',
        required: true,
        hidden: true,
        direction: 'ParamInput'
    }];

    data.params = data.params.concat(callbackParam);

}, [installedApps]);