import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ButtonRedirect = ({ path = '/workflow', id, action, label }) => {

	const navigate = useNavigate();

	let url = `${path}/${id}`;
	
	if (action) {
		url += `/${action}`;
	}

	return <button type="button" onClick={() => navigate(url)}>{label}</button>;
};

ButtonRedirect.displayName = 'ButtonRedirect';

ButtonRedirect.propTypes = {
	path: PropTypes.string,
	id: PropTypes.string.isRequired,
	action: PropTypes.string,
	label: PropTypes.string.isRequired
};

export default ButtonRedirect;