import { useState, useEffect } from 'react';
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import DataTable from 'react-data-table-component';
import Header from './Components/Header';
import Sidebar from './Components/Sidebar';
import CreateAPIKeyModal from './Components/CreateAPIKeyModal';
import SyncButton from './Components/SyncButton';
import DeleteWorkflowModal from './Components/DeleteWorkflowModal';
import { useTranslation } from 'react-i18next';
import { useGetApikeys } from './Editor/Hooks/useGetApikeys';
import { useCreateAPIKeywHandler } from './Editor/Hooks/useCreateAPIKeywHandler';
import { useDeleteAPIKeyHandler } from './Editor/Hooks/useDeleteAPIKeyHandler';
import { useDatatableColumnsAPIKey } from './Editor/Hooks/useDatatableColumnsAPIKey';
import Loading from './Components/Loading';
import PageHeader from './Components/PageHeader';
import { customRowStyles } from './Editor/js/styles';

const APIKeys = () => {

    const [loading, setLoading] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [dataComponent, setDataComponent] = useState([]);
    const [pending, setPending] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [apiKeyId, setApiKeyId] = useState('');
    const [inputDelete, setInputDelete] = useState('');

    const { t } = useTranslation();

    const breadcrumbItems = [
        { label: 'Dashboard', link: '/dashboard' },
        { label: t('sidebar.settings'), link: '' },
        { label: t('sidebar.settings.api-keys'), link: '/settings/api-keys' },
    ];

    const getApikeys = useGetApikeys(setPending, setDataComponent);

    const createAPIKeywHandler = useCreateAPIKeywHandler(setShowModal, setLoading, setPending, setDataComponent);

    const deleteAPIKeyHandler = useDeleteAPIKeyHandler(setLoading, setPending, setDataComponent, setShowModalDelete, setInputDelete)

    const columns = useDatatableColumnsAPIKey(setApiKeyId, setShowModalDelete);

    const rightContent = (
        <button onClick={() => setShowModal(true)} className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-10 ease-linear transition-all duration-150" type="button">
            {t('create_api_key')}
        </button>
    );

    useEffect(() => {
        getApikeys();
    }, [getApikeys]);
    
    return (

        <ReactFlowProvider>

            {
                loading ? (
                    <Loading />
                ) : (

                    <div className="flow-wrapper">

                        <Header />

                        <div className="flow-editor">

                            <Sidebar isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} />

                            <div className="flow-editor-wrapper">

                                <PageHeader title={t('sidebar.settings.api-keys')} breadcrumbItems={breadcrumbItems} rightContent={rightContent}/>

                                {showModal && <CreateAPIKeyModal setShowModal={setShowModal} createAPIKeywHandler={createAPIKeywHandler} />}

                                {showModalDelete &&
                                    <DeleteWorkflowModal setShowModalDelete={setShowModalDelete} deleteWorkflowHandler={deleteAPIKeyHandler} workflowId={apiKeyId} inputDelete={inputDelete} setInputDelete={setInputDelete}/>
                                }

                                {dataComponent && dataComponent.length === 0 ? (
                                        <div className="relative m-10 p-40 border border-gray-300 rounded-md text-center">
                                            <h1 className="text-2xl font-semibold mb-4">{t('start_creating_api_keys')}</h1>
                                            <p className="text-base text-gray-600">{t('text_api_keys')}</p>
                                        </div>
                                    ) : (
                                        <div className="relative m-10" style={{ border: '1px solid #e5e7eb', borderRadius: '0.375rem', padding: '2rem' }}>
                                            {!pending && (
                                                <SyncButton onSync={getApikeys} />
                                            )}
                                            <DataTable columns={columns} data={dataComponent} progressPending={pending} pagination customStyles={customRowStyles} />
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                )
            }
        </ReactFlowProvider>
    );
};

export default APIKeys;