import axios from '../Config/axios';

export async function fetchAllApikeys() {

    try {

        const response = await axios.get('/api/settings/api-keys');

        return response.data;

    } catch (error) {

        throw new Error('Error fetching api keys');

    }
}

export async function createApikey(title) {
    
    try {

        const data = { title };
        
        const response = await axios.post('/api/settings/api-keys/create', data);

        return response.data;

    } catch (error) {
        throw new Error('Error creating api key');
    
    }
}

export async function deleteApikey(ApikeyId) {

    try {

        console.log(`/api/settings/api-keys/${ApikeyId}/delete`);
        
        const response = await axios.delete(`/api/settings/api-keys/${ApikeyId}/delete`);

        return response.data;

    } catch (error) {

        throw new Error('Error deleting api key');

    }
}