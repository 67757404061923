const Node = () => {
    return {

        bpFunc: 'Switch',

        title: 'Switch',
        label: 'Switch',
        icon: '/icon_action.png',
        leftHandler: true,
        params: [
            {
                name: 'input',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamInput'
            }
        ],
        nodes: 4
    };
};

export default Node;