import { useCallback } from 'react';
import { updateNodeData } from '../Utils';

export const useHandleNodeChange = (nodeDetails, reactFlowInstance, setNodeDetails) => useCallback((event, index) => {

    if (!nodeDetails) return;

    const getInputValue = (input, type) => {

        if (type === 'nodes') {
            let value = parseInt(input, 10);
            return Math.min(Math.max(value || 1, 1), 31);
        }

        return input;
    };

    reactFlowInstance.setNodes((nds) =>

        nds.map((node) => {

            if (node.id !== nodeDetails.id) return node;

            const input = event.target.value || '';

            let newData = {};

            if (index === 'title') {

                newData.title = input;

            } else if (index === 'nodes') {

                newData.nodes = getInputValue(input, 'nodes');

            } else if (typeof index === 'object' && index.type === 'custom') {

                if (node.data && node.data.custom_params) {

                    let custom_params = node.data.custom_params;

                    if (custom_params[index.key]) {
                        custom_params[index.key][index.field] = input;
                    } else {
                        custom_params[index.key] = { [index.field]: input };
                    }

                    newData.custom_params = custom_params;
                }

            } else {

                if (node.data && node.data.params && node.data.params[index] !== undefined) {
                    let params = node.data.params;

                    params[index].value = input;

                    newData.params = params;
                }
            }

            const updatedNode = updateNodeData(node, newData);

            setNodeDetails(updatedNode);

            return updatedNode;

        })
    );

}, [nodeDetails, reactFlowInstance, setNodeDetails]);