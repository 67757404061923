import { useState, useEffect } from 'react';
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';

import Header from './Components/Header';
import Sidebar from './Components/Sidebar';

import { useTranslation } from 'react-i18next';
import { useGetInstalledApps } from './Editor/Hooks/useGetInstalledApps';
import Loading from './Components/Loading';
import PageHeader from './Components/PageHeader';

const Integrations = () => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const breadcrumbItems = [
        { label: 'Dashboard', link: '/dashboard' },
        { label: t('sidebar.integrations'), link: '/integrations' },
    ];

    const [installedApps, setInstalledApps] = useState(null);

    const getInstalledApps = useGetInstalledApps(setLoading, setInstalledApps);

    function TruncateText({ text, maxLength }) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }

    useEffect(() => {
        getInstalledApps();
    }, [getInstalledApps]);

    return (

        <ReactFlowProvider>

            {
                loading ? (
                    <Loading />
                ) : (

                    <div>
                        <div className="flow-wrapper">

                            <Header />

                            <div className="flow-editor">

                                <Sidebar isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} />

                                <div className="flow-editor-wrapper">

                                    <PageHeader title={t('sidebar.integrations')} breadcrumbItems={breadcrumbItems} />

                                    <div className="grid grid-cols-4 gap-4 m-10" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))' }}>
                                        
                                        {installedApps && installedApps.length > 0 && installedApps.map(app => (

                                            <div key={app.id}>

                                                <div className="col-span-1 bg-white shadow-md rounded-lg overflow-hidden" key={app.id} style={{ padding: '20px' }}>
                                                    
                                                    <a href={`https://apps.iugu.com/${app.slug}`} className="block p-4 bg-gray-100 hover:bg-gray-200" target="_blank" rel="noreferrer noopener">
                                                        <div className="text-center flex flex-col items-center">
                                                            <img src={app.logo_url} alt={app.description} className="w-16 h-16 object-cover" style={{ height: '60px' }} />
                                                            <h6 className="text-lg font-semibold text-gray-900 mt-2">{app.name}</h6>
                                                            <div className="w-full h-1 bg-blue-600 my-2"></div>
                                                            <TruncateText text={app.description} maxLength={100} />
                                                        </div>
                                                        <div className="flex flex-col items-center mt-4">
                                                            <p className="text-gray-500">{t('categories')}</p>
                                                        </div>
                                                        <div className="text-center mt-1">
                                                            <ul>
                                                                {Object.entries(app.categories).map(([key, value]) => (
                                                                    <li key={key} className="text-sm text-gray-700">
                                                                        <span key={key}>{value}</span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <div className="flex flex-col items-center mt-4">
                                                            <p className="text-gray-500">{t('actions')}</p>
                                                        </div>
                                                        <div className="text-center mt-1">
                                                            <ul>
                                                                {app.actions && app.actions.length > 0 && app.actions.map(action => (
                                                                    <li key={action} className="text-sm text-gray-700">
                                                                        <span>{action}</span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </ReactFlowProvider>
    );
};

export default Integrations;