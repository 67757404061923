import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { createApikey } from '../../../Services/APIKeyService';
import { useGetApikeys } from './useGetApikeys';

export const useCreateAPIKeywHandler = (setShowModal, setLoading, setPending, setDataComponent) => {

    const getApikeys = useGetApikeys(setPending, setDataComponent);

    return useCallback(async (e) => {

        e.preventDefault();

        setShowModal(false);
        setLoading(true);

        const title = e.target.title.value;

        try {

            await createApikey(title);

            getApikeys();

        } catch (error) {

            handleResponseError(error);

        } finally {

            setLoading(false);

        }

    }, [setShowModal, setLoading, getApikeys]);

};