import { useState, useEffect } from 'react';
import {
    useReactFlow,
    useOnSelectionChange
} from 'reactflow';
import InputParam from '../Components/InputParam';
import ModalPayload from '../Components/ModalPayload';
import NodeInspectorCustomParamsComponent from '../Components/NodeInspectorCustomParamsComponent';
import NodeInspectorOutputComponent from '../Components/NodeInspectorOutputComponent';
import NodeInspectorSwitchComponent from '../Components/NodeInspectorSwitchComponent';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const NodeInspector = ({ nodeId, setNodeId, edges, updateConnectionLabel, RunWorkflow, setNodeDetails, nodeDetails, handleChange, currentWorkflow}) => {

    const [showNodeInspector, setShowNodeInspector] = useState(true);

    const reactFlowInstance = useReactFlow();

    const [editingConnectionId, setEditingConnectionId] = useState(null);

    const [showModal, setShowModal] = useState(false);

    const { t } = useTranslation();

    const handleAddField = () => {

        const updatedParameters = [...nodeDetails.data.custom_params, { name: '', value: '', type: 'string', direction: 'ParamOutput'}];

        nodeDetails.data.custom_params = updatedParameters;

        setNodeDetails({ ...nodeDetails });
    };

    const handleRemoveField = (index) => {

        if (nodeDetails && nodeDetails.data && nodeDetails.data.custom_params && nodeDetails.data.custom_params[index]) {

            const updatedParameters = [...nodeDetails.data.custom_params];

            updatedParameters.splice(index, 1);

            nodeDetails.data.custom_params = updatedParameters;

            setNodeDetails({ ...nodeDetails });
        }
    };

    useOnSelectionChange({
        onChange: ({ nodes }) => {
            if (nodes.length === 1) {
                setNodeId(nodes[0].id);
            } else {
                setNodeId(null);
            }
        }
    }, [setNodeId]);

    const handleConnectionLabelChange = (edgeId, newLabel) => {
        updateConnectionLabel(edgeId, newLabel);
    };

    const handleConnectionLabelBlur = () => {
        setEditingConnectionId(null);
    };

    useEffect(() => {
        
        if (!nodeId) {
            setNodeDetails(null);
            return;
        }

        const newNodeDetails = reactFlowInstance.getNode(nodeId);

        setNodeDetails(newNodeDetails);

        if (newNodeDetails && newNodeDetails.data.hide_nodeinspector){
            setShowNodeInspector(false);
        }
        else{
            setShowNodeInspector(true);
        }

    }, [reactFlowInstance, nodeId, setNodeDetails]);

    if (!nodeDetails) {
        return (<div></div>);
    }

    return (

        showNodeInspector ? (
            
            <div style={{ padding: '20px' }}>

                {showModal && <ModalPayload setShowModal={setShowModal} nodeDetails={nodeDetails} RunWorkflow={RunWorkflow} />}
                
                <aside className="inspector" style={{ maxHeight: '87vh', overflow: 'scroll' }}>
                    
                    <div style={{ marginBottom: '15px', borderBottom: '1px solid #ccc', paddingBottom: '15px' }}>
                        <div className="flex justify-between">
                            <h3 className="pb-3 text-xs text-gray-500">
                                <span className="text-xs font-semibold inline-block py-1 px-2 rounded text-black-600 bg-gray-200 last:mr-0 mr-1">
                                    Título
                                </span>
                            </h3>

                            {currentWorkflow && currentWorkflow.workflow_json ? (

                                <span className="text-xs text-gray-500">
                                    <div className="bp-icon">
                                        <span>
                                            <button onClick={() => setShowModal(true)}> 
                                                <span className="text-xs font-semibold uppercase inline-block py-1 px-2 rounded text-blue-600 bg-blue-100 last:mr-0 mr-1">
                                                    {t('execute')}
                                                </span>
                                            </button >
                                        </span>
                                    </div>
                                </span>

                            ) : null}

                        </div>
                        <input className="px-2 py-1 input-style placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border-1 shadow outline-none focus:outline-none focus:ring w-full" value={nodeDetails.data.title} type="text" onChange={(e) => handleChange(e, 'title')} style={{ height: '30px' }} />
                    </div>

                    {nodeDetails.data.params && nodeDetails.data.params.filter(param => param.direction === 'ParamInput' && param.name !== 'callback_url' && param.name !== 'callback_app_id' && param.name !== 'payload').map((param, index) => (
                        <InputParam key={index} param={param} index={index} handleChange={handleChange} />
                    ))}

                    {nodeId != null && (
                        <NodeInspectorSwitchComponent nodeId={nodeId} edges={edges} nodeDetails={nodeDetails} handleConnectionLabelChange={handleConnectionLabelChange} handleConnectionLabelBlur={handleConnectionLabelBlur} setEditingConnectionId={setEditingConnectionId} handleChange={handleChange} editingConnectionId={editingConnectionId} />
                    )}

                    <NodeInspectorCustomParamsComponent nodeDetails={nodeDetails} handleAddField={handleAddField} handleRemoveField={handleRemoveField} handleChange={handleChange} />

                    <NodeInspectorOutputComponent nodeDetails={nodeDetails} />

                </aside>
            </div>

        ) : null
    );

};

NodeInspector.propTypes = {
    nodeDetails: PropTypes.object,
    edges: PropTypes.array.isRequired,
    nodeId: PropTypes.string,
    setNodeId: PropTypes.func.isRequired,
    updateConnectionLabel: PropTypes.func.isRequired,
    RunWorkflow: PropTypes.func.isRequired,
    setNodeDetails: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired
};

export default NodeInspector;