import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { fetchAllWorkflowIntances } from '../../../Services/WorkflowService';

export const useGetWorkflowIntances = (setPending, setInputStartDate, setInputEndDate, setSelectStatus, setWorkflowInstancesComponent) => useCallback(async () => {

    setPending(true);

    try {

        setInputStartDate('');
        setInputEndDate('');
        setSelectStatus('');
        setSelectStatus('');

        const data = await fetchAllWorkflowIntances();

        setWorkflowInstancesComponent(data);

    } catch (error) {

        handleResponseError(error);

    } finally {

        setPending(false);

    }

}, [setPending, setInputStartDate, setInputEndDate, setSelectStatus, setWorkflowInstancesComponent]);