
const smtpParameters = [
    { name: 'mail_host', alias: 'Host', value: '', type: 'string', required: true, direction: 'ParamInput' },
    { name: 'mail_port', alias: 'Port', value: '', type: 'string', required: true, direction: 'ParamInput' },
    { name: 'mail_username', alias: 'Username', value: '', type: 'string', required: true, direction: 'ParamInput' },
    { name: 'mail_password', alias: 'Password', value: '', type: 'string', required: true, direction: 'ParamInput' },
    { name: 'mail_encryption', alias: 'Encryption', value: 'tls', type: 'string', required: true, direction: 'ParamInput', hidden: true },
    { name: 'mail_subject', alias: 'Subject', value: '', type: 'string', required: true, direction: 'ParamInput' },
    { name: 'mail_from', alias: 'From', value: '', type: 'string', required: true, direction: 'ParamInput' },
    { name: 'mail_to', alias: 'To', value: '', type: 'string', required: true, direction: 'ParamInput' },
    { name: 'mail_body', alias: 'Body', value: '', type: 'textarea', required: true, direction: 'ParamInput' }
];

const Node = () => {
    return {

        bpFunc: 'SMTP',

        title: 'SMTP',
        label: 'SMTP',
        icon: '/icon_action.png',
        leftHandler: true,
        params: smtpParameters
    };
};

export default Node;
