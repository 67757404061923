import { useState, useEffect } from 'react';
import {ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import Header from './Components/Header';
import Sidebar from './Components/Sidebar';
import DataTable from 'react-data-table-component';
import ExpandedComponent from './Components/ExpandedComponent';
import { useParams } from 'react-router-dom';
import SyncButton from './Components/SyncButton';
import { useTranslation } from 'react-i18next';
import { useGetWorkflowExecutionById } from './Editor/Hooks/useGetWorkflowExecutionById';
import { useGetExecutionActionByIdAndNode } from './Editor/Hooks/useGetExecutionActionByIdAndNode';
import { useDatatableColumnsEvent } from './Editor/Hooks/useDatatableColumnsEvent';
import PageHeader from './Components/PageHeader';
import { customRowStyles } from './Editor/js/styles';

const Events = () => {

    const [dataComponent, setDataComponent] = useState([]);
    const [pending, setPending] = useState(false);
    const { workflowExecutionId } = useParams();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { t } = useTranslation();

    const breadcrumbItems = [
        { label: 'Dashboard', link: '/dashboard' },
        { label: t('sidebar.instances'), link: '/instances' },
        { label: `${workflowExecutionId}`, link: `/workflows/instance/${workflowExecutionId}`}
    ];

    const getWorkflowExecutionById = useGetWorkflowExecutionById(setPending, setDataComponent);

    const getExecutionActionByIdAndNode = useGetExecutionActionByIdAndNode(setPending, setDataComponent);

    const columns = useDatatableColumnsEvent(getExecutionActionByIdAndNode, workflowExecutionId);

    useEffect(() => {
        getWorkflowExecutionById(workflowExecutionId);
    }, [getWorkflowExecutionById, workflowExecutionId]);

    return (

        <ReactFlowProvider>
        
            <div className="flow-wrapper">
                
                <Header />
                
                <div className="flow-editor">

                    <Sidebar isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen}/>

                    <div className="flow-editor-wrapper">

                        <PageHeader title={t('events')} breadcrumbItems={breadcrumbItems} />

                        <div className="relative m-10" style={{ border: '1px solid #e5e7eb', borderRadius: '0.375rem', padding: '2rem' }}>

                            {!pending && (
                                <SyncButton onSync={getWorkflowExecutionById} workflowExecutionId={workflowExecutionId} />
                            )}

                            <DataTable columns={columns} data={dataComponent} progressPending={pending} pagination paginationPerPage={10} paginationRowsPerPageOptions={[10, 20, 50, 100, 500, 1000]} expandableRows expandableRowsComponent={ExpandedComponent} customStyles={customRowStyles}/>

                        </div>

                    </div>
                </div>
            </div>
        </ReactFlowProvider>
    );
};

export default Events;