import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const AlertBar = ({ message, showAlert, setShowAlert, showEvents, setShowModalWorkflowInstanceEventsLogs, setShowErrorAlert}) => {

    const { t } = useTranslation();

    useEffect(() => {

        setShowErrorAlert(false);

        const timer = setTimeout(() => {
            setShowAlert(false);
        }, 10000);

        return () => clearTimeout(timer);

    }, [setShowAlert, setShowErrorAlert]);

    const styles = {
        position: 'fixed',
        bottom: showAlert ? '0' : '-100px',
        width: 'auto',
        height: '50px',
        transition: 'bottom 0.5s ease-out',
        display: showAlert ? 'block' : 'none',
        zIndex: 9999,
    };
    
    return (
        <>
            {showAlert ? (
                <div style={styles} className='mb-5 m-20'>
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-emerald-500">
                        <span className="text-xl inline-block mr-1 align-middle">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mercado-match mr-1" width="24" height="24" focusable="false">
                                <path d="M12 2a10 10 0 1010 10A10 10 0 0012 2zm-1.25 15L7 13.25l1.41-1.41L10.59 14l4.84-6H18z"></path>
                            </svg>
                        </span>
                        <span className="inline-block align-middle mr-8">
                        <b>{message}</b>
                        {showEvents ? (
                            <button onClick={(e) => {
                                e.preventDefault();
                                setShowAlert(false);
                                setShowModalWorkflowInstanceEventsLogs(true);
                            }}> (<span className="hover:underline capitalize">{t('show_events')}</span>)</button>
                        ) : null}
                        </span>
                        <button
                            className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowAlert(false);
                            }}
                        >
                            <span>×</span>
                        </button>
                    </div>
                </div>
            ) : null}
        </>
    );
};

AlertBar.displayName = 'AlertBar';

AlertBar.propTypes = {
    message: PropTypes.string.isRequired,
    showAlert: PropTypes.bool.isRequired,
    setShowAlert: PropTypes.func.isRequired,
    showEvents: PropTypes.bool.isRequired,
    setShowModalWorkflowInstanceEventsLogs: PropTypes.func.isRequired,
    setShowErrorAlert: PropTypes.func.isRequired
};

export default AlertBar;
