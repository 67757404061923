import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ModalPayload = ({setShowModal, nodeDetails, RunWorkflow}) => {

    const [payload, setPayload] = useState('');
    const [error, setError] = useState('');

    const { t } = useTranslation();

    const checkPayload = useCallback(async (e) => {

        let str = payload;

        try {


            if (str.startsWith('{') && str.endsWith('}')) {
                str = JSON.parse(str);
            }

            setError('');
            setShowModal(false);
    
            await RunWorkflow(e, parseInt(nodeDetails.id), str);
        
        } catch (e) {

            console.log(e);
            setError('Invalid JSON!');
        }

    }, [RunWorkflow, nodeDetails.id, setShowModal, payload]);    

    return (

        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto" style={{ width: '50%' }}>

                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        <div className="flex justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            
                            <h3 className="pb-3 uppercase text-xs text-gray-500 ">
                                <span className="text-xs font-semibold inline-block py-1 px-2 rounded text-blue-600 bg-blue-200 last:mr-0 mr-1">
                                    {t('enterVariablesInJsonFormat')}
                                </span>
                                
                                <span className="text-xs inline-block py-1 px-2 rounded text-gray-600 bg-gray-200 last:mr-0 mr-1">
                                    {t('node')}: #{nodeDetails.id}
                                </span>
                            </h3>

                        </div>

                        <div className="relative p-6" style={{ maxHeight: '500px', overflowY: 'auto' }}>

                            <textarea
                                className="textarea-style"
                                style={{ resize: 'vertical', height: 'auto' }}
                                rows="10"
                                placeholder="{}"
                                onChange={(e) => setPayload(e.target.value)}
                            ></textarea>

                            <span className='text-red-500 background-transparent text-sm outline-none focus:outline-none ease-linear transition-all duration-150'>
                                <p className="mt-5">
                                    {error && <span style={{ color: 'red' }}>{error}</span>}
                                </p>
                            </span>

                        </div>

                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setShowModal(false)}
                            >
                                {t('close')}
                            </button>
                            <button onClick={(e) => checkPayload(e)} className={'bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'}>
                                {t('execute')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
               
        </>
    );
};

ModalPayload.displayName = 'ModalPayload';

ModalPayload.propTypes = {
    setShowModal: PropTypes.func.isRequired,
    nodeDetails: PropTypes.object.isRequired,
    RunWorkflow: PropTypes.func.isRequired,
};

export default ModalPayload;