const Node = () => {
    return {
        bpFunc: 'GetDate',

        title: 'GetDate',
        label: 'GetDate',
        icon: '/icon_action.png',
        leftHandler: true,
        params: [
            {
                name: 'date',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            },
            {
                name: 'date_ptbr',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            },
            {
                name: 'day',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            },
            {
                name: 'month',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            },
            {
                name: 'year',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            },
        ]
    };
};

export default Node;