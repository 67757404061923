import { useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import WorkspaceDetailsComponent from './WorkspaceDetailsComponent';

const Header = ({
    updateWorkflowHandler,
    currentWorkflow,
    setShowModalWorkflowInstances
}) => {

    const { t, i18n } = useTranslation();

    const language = localStorage.getItem('language') || 'en';

    useEffect(() => {
        const lng = language === 'pt-BR' ? 'pt' : language;  
        localStorage.setItem('language', lng); 
        i18n.changeLanguage(lng);
    }, [language, i18n]);
    
    const ShowLogs = currentWorkflow ? (
        <button onClick={() => setShowModalWorkflowInstances(true)}>
            <span className="font-bold uppercase text-sm px-4 py-2 mt-2 rounded text-blue-600 bg-blue-100">
                {t('sidebar.instances')}
            </span>
        </button >) : (<></>);

    return (<header>

        <div className="h-16 flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">

            <span className="text-base font-semibold leading-7 text-gray-900">
                <a href="https://console.iugu.com" className="text-blue-500 hover:text-blue-700 underline">
                    {t('back')}
                </a>
            </span>

            <span className="order-last flex w-full gap-x-8 text-3xl font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                <a href="https://workflow.iugu.com/automations">Workflows</a>
            </span>

            <div className="flex gap-2 order-last flex items-center  ml-auto">

                {ShowLogs}

                <WorkspaceDetailsComponent currentWorkflow={currentWorkflow} updateWorkflowHandler={updateWorkflowHandler} />

            </div>
        </div>

    </header>);
};

Header.displayName = 'Header';

Header.propTypes = {
    updateWorkflowHandler: PropTypes.func,
    currentWorkflow: PropTypes.object,
    setShowModalWorkflowInstances: PropTypes.func,
};

export default Header;