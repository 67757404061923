import { useCallback } from 'react';
import { useCalculatePosition } from './useCalculatePosition';
import { getEventType, handleTriggerType, getEventData, createNewNode } from '../Utils';
import DataComponent from '../DataComponent';

export const useOnDrop = (setNodes, handleMarketplaceType, reactFlowInstance, reactFlowWrapper) => {
    
    const calculatePosition = useCalculatePosition(reactFlowInstance, reactFlowWrapper);

    return useCallback(async (event) => {

        event.preventDefault();

        const eventType = getEventType(event);

        if (!eventType) return;

        const position = calculatePosition(event);

        const data = DataComponent({ type: eventType });

        if (data.type === 'trigger') {
            handleTriggerType(data);
        }

        if (eventType === 'marketplace') {
            await handleMarketplaceType(data, event);
        }

        const eventData = getEventData(event);

        if (data.bpFunc === 'Blueprint') {
        
            let jsonEventData = JSON.parse(eventData);

            data.workflow_id = jsonEventData.id;

            data.label = jsonEventData.label;

        }

        const newNode = createNewNode(data, position);

        setNodes((nds) => nds.concat(newNode));

    }, [setNodes, calculatePosition, handleMarketplaceType]);

};