import { useTranslation } from 'react-i18next';

export const useMoneyFormat = () => {
    
    const { t } = useTranslation();

    return (cents) => {
       
        let currencyCode = t('currency_code');
        let locale;

        switch(currencyCode) {
            case 'BRL':
                locale = 'pt-BR';
                break;
            case 'USD':
                locale = 'en-US';
                break;
            case 'EUR':
                locale = 'de-DE';
                break;
            default:
                throw new Error('Currency code not supported');
        }

        return (cents / 100).toLocaleString(locale, { style: 'currency', currency: currencyCode });
    };
};
