const Node = () => {
    return {

        bpFunc: 'Join',

        title: 'Join',
        label: 'Join',
        icon: '/icon_action.png',
        leftHandler: true,
        params: [
            {
                name: 'parts',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'delimiter',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'output',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            },
        ]
    };
};

export default Node;