import { useCallback } from 'react';

export const useHandleChange = (nodeDetails, setNodeDetails) => useCallback((e, key) => {

    if (!nodeDetails) return;

    const { name, value } = e.target;

    setNodeDetails(prevDetails => {

        const updatedParams = [...prevDetails.data.custom_params];

        if (updatedParams[key] !== undefined) {
            updatedParams[key] = {
                ...updatedParams[key],
                [name]: value
            };
        }

        return {
            ...prevDetails,
            data: {
                ...prevDetails.data,
                custom_params: updatedParams
            }
        };
    });

}, [nodeDetails, setNodeDetails]);