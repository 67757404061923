import { useCallback } from 'react';
import { fetchWorkflowById } from '../../../Services/WorkflowService';

function handleNodeParams(node, fnParams) {
    const dataParams = node.data.custom_params;
    for (const param of dataParams) {
        param.direction = node.type === 'input_function' ? 'ParamInput' : 'ParamOutput';
        param.required = true;
    }
    fnParams(dataParams);
}

function findNodeByCondition(nodes, condition) {
    return nodes.find(node => condition(node));
}

function updateDataParams(data, foundInputNode, workflowId) {
    data.params.forEach(param => {
        if (param.name === 'workflow') {
            param.value = workflowId;
        } else if (param.name === 'step') {
            param.value = foundInputNode.id;
        }
    });
}

export const useGetBlueprintNodeByWorkflowId = (data, setInputParams, setOutputParams, updateNodeDetails) => useCallback(async (workflowId) => {
        
    try {

        const workflow = await fetchWorkflowById(workflowId);

       if (!workflow || !workflow.workflow_json) return;

        const { nodes } = JSON.parse(workflow.workflow_json);

        if (nodes) {

            nodes.filter(node => node.type === 'input_function' || node.type === 'output_function').forEach(node => {
                handleNodeParams(node, node.type === 'input_function' ? setInputParams : setOutputParams);
            });

            const foundInputNode = findNodeByCondition(nodes, node => node.data && node.data.bpFunc === 'Input');

            if (foundInputNode) {
                updateDataParams(data, foundInputNode, workflowId);
            }

            const foundOutputNode = findNodeByCondition(nodes, node => node.data && node.data.bpFunc === 'Output');
            
            if (foundOutputNode && foundOutputNode.data.custom_params.length > 0) {
                const nodeOutputParam = foundOutputNode.data.custom_params[0];
                data.params.push(nodeOutputParam);
            }
        }

        updateNodeDetails(data);

    } catch (error) {
        console.log(error);
    }
        
}, [data, updateNodeDetails, setInputParams, setOutputParams]);
