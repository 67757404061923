import axios from '../Config/axios';

export async function updateWorkflow(workflowId, data) {

    try {

        const response = await axios.post(`/api/workflows/${workflowId}/save`, data);

        return response.data;

    } catch (error) {
        
        if (error.response) {
            throw new Error(error.response.data.error);
        } else {
            throw new Error('Error updating workflow');
        }

    }
}


export async function fetchAllWorkflows(type) {

    try {

        if (!type) {
            type = '';
        }

        const response = await axios.get('/api/workflows?type=' + type);

        return response.data;

    } catch (error) {

        throw new Error('Error fetching workflows');

    }
}

export async function fetchAllWorkflowSchemas(workflowId) {

    try {

        const response = await axios.get(`/api/workflows/${workflowId}/schemas`);

        return response.data;

    } catch (error) {

        throw new Error('Error fetching workflow schemas');

    }
}

export async function fetchAllWorkflowIntancesByWorkflowId(workflowId) {

    try {

        const response = await axios.get(`/api/workflows/${workflowId}/instances`);

        return response.data;

    } catch (error) {

        throw new Error('Error fetching workflow instances by workflow id');

    }
}

export async function fetchInfoMarketplaceApp(externalUrl) {

    try {

        const response = await axios.get(externalUrl);

        return response.data;

    } catch (error) {

        throw new Error('Error fetching info marketplace app');

    }
}

export async function fetchAllWorkflowIntances() {

    try {

        const response = await axios.get('/api/workflows/instances');

        return response.data;

    } catch (error) {

        throw new Error('Error fetching workflow instances');

    }
}

export async function fetchAllWorkflowIntancesByFilters(filters) {

    try {
    
        const response = await axios.get('/api/workflows/instances', {
            params: filters,
        });

        return response.data;

    } catch (error) {

        throw new Error('Error fetching workflow instances by filters');

    }
}

export async function fetchGetInstalledApps() {

    try {

        const response = await axios.get('/api/workspace/apps');

        return response.data;

    } catch (error) {

        throw new Error('Error fetching installed apps');

    }
}

export async function fetchGetInstalledAppsActions() {

    try {

        const response = await axios.get('/api/workspace/apps/actions');

        return response.data;

    } catch (error) {

        throw new Error('Error fetching installed apps actions');

    }
}

export async function fetchWorkflowById(workflowId) {

    try {

        const response = await axios.get(`/api/workflows/${workflowId}/read`);

        return response.data;

    } catch (error) {

        throw new Error('Error fetching workflow by id');

    }
}

export async function fetchWorkflowExecutionById(workflowExecutionId) {

    try {

        const response = await axios.get(`/api/workflows/instance/${workflowExecutionId}`);

        return response.data;

    } catch (error) {

        throw new Error('Error fetching workflow execution by id');

    }
}

export async function fetchExecutionActionByIdAndNode(workflowExecutionId, node) {

    try {
        
        const response = await axios.post(`/api/workflows/instance/${workflowExecutionId}/run/${node}`);

        return response.data;

    } catch (error) {

        throw new Error('Error fetching execution action by id and node');

    }
}

export async function createWorkflow(data, workflowJson) {
    
    try {
        
        if (workflowJson) {
            data.workflow_json = workflowJson;
        }

        const response = await axios.post('/api/workflows/create', data);

        return response.data;

    } catch (error) {
        
        throw new Error('Error creating workflow');
    
    }
}

export async function executeWorkflowSchemaByNode(workflowId, schemaId, node, payload) {
    
    try {

        const response = await axios.post(`/api/workflows/${workflowId}/upgrade/${schemaId}/execute/${node}`, payload);

        return response.data;

    } catch (error) {
        throw new Error('Error executing workflow schema');
    }

}

export async function deleteWorkflow(workflowId) {

    try {

        const response = await axios.delete(`/api/workflows/${workflowId}`);

        return response.data;

    } catch (error) {

        throw new Error('Error deleting workflow');

    }
}

export async function runWorkflow(payload) {

    try {
                
        const response = await axios.post('/api/debug/workflow', payload);
        
        return response.data;

    } catch (error) {
       
        throw new Error('Error running workflow');
    
    }

}