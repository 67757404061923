import { Handle, Position } from 'reactflow';
import React from 'react';

const textTitleStyle = {
    backgroundColor: '#f5f5f5',
    borderRadius: '5px',
    marginBottom: '5px',
    textAlign: 'center',
};

const NameInput = ({ index, value, onChange }) => (
    <input
        className="px-2 py-1 input-style placeholder-slate-300 text-slate-600 relative bg-white rounded text-sm border-1 shadow outline-none focus:outline-none focus:ring w-full"
        style={{ height: '20px' }}
        value={value}
        name="name"
        onChange={e => onChange(e, index)}
        placeholder="name"
        type="text"
    />
);

const ValueInput = ({ index, value, type, onChange }) => (
    <div style={{ width: '100%' }}>
    
        <input
            className="px-2 py-1 input-style placeholder-slate-300 text-slate-600 relative bg-white rounded text-sm border-1 shadow outline-none focus:outline-none focus:ring w-full"
            placeholder="value"
            name="value"
            value={value}
            onChange={e => onChange(e, index)}
            type="text"
            style={{ width: '55%', fontSize: '12px', marginBottom: '5px', float: 'left', height: '20px', marginRight: '5px' }}
        />
        <TypeSelect index={index} type={type} onChange={onChange} />

    </div>
);

const TypeSelect = ({ index, type, onChange }) => (
    <select
        value={type}
        onChange={e => onChange(e, index)}
        name="type"
        className="px-2 py-1 input-style placeholder-slate-300 text-slate-600 relative bg-white rounded text-sm border-1 shadow outline-none focus:outline-none focus:ring w-full"
        style={{ width: '40%', fontSize: '12px', float: 'right', height: '20px', padding: '0px 0px 0px 5px' }}
    >
        <option value="string">String</option>
        <option value="integer">Integer</option>
        <option value="boolean">Boolean</option>
        <option value="float">Float</option>
    </select>
);

export const useRenderCustomParams = (nodeDetails, handleChange, data, id, type) => () => {

    const generateNodeId = (bpFunc) => {
        return `${id}-${bpFunc}-${type}-custom_params`.replace(/\s+/g, '-').toLowerCase();
    };

    const { leftHandler, rightHandler, bp_func_alias, bpFunc } = data;

    if (!nodeDetails) return null;

    const baseId = generateNodeId(bpFunc);

    return nodeDetails.data.custom_params.map((param, index) => {

        const handleId = `handleId-${baseId}-${index}`;

        return (

            <React.Fragment key={index}>

                <div key={index} className='bg-output-node'>

                    <div className='text-title' style={textTitleStyle}>
                        <NameInput index={index} value={param.name} onChange={handleChange} />
                    </div>

                    <div style={{ borderRadius: '50%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ width: '100%' }}>
                                <ValueInput index={index} value={param.value} type={param.type} onChange={handleChange} />
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                {leftHandler && <Handle type="target" className="react-flow__handle--in" position={Position.Left} id="in" />}

                                {rightHandler &&
                                    <Handle
                                        type="source"
                                        className="react-flow__handle--out"
                                        key={handleId}
                                        position={Position.Right}
                                        id={handleId}
                                        style={{
                                            background: '#555',
                                            position: 'relative',
                                            right: '-20px',
                                            top: '-7px'
                                        }}
                                    />
                                }
                            </div>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    });
};