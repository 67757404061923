import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useDatatableColumnsAPIKey = (setApiKeyId, setShowModalDelete) => {

    const { t } = useTranslation();
    const [copyStatus, setCopyStatus] = useState({});

    const copyToClipboard = (text, rowId) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopyStatus(prevStatus => ({ ...prevStatus, [rowId]: t('copied') }));
            setTimeout(() => {
                setCopyStatus(prevStatus => ({ ...prevStatus, [rowId]: t('copy') }));
            }, 1500);
        }).catch(err => console.error('Failed to copy text: ', err));
    };
    
    const handleClickDelete = (id) => {
        setApiKeyId(id);
        setShowModalDelete(true);
    };

    const columns = [
        {
            name: <span className="text-base">{t('name')}</span>,
            selector: row => row.name,
            grow: 4,
        },
        {
            name: <span className="text-base">{t('api_key')}</span>,
            selector: row => {
                let str = row.apikey;
                return str.length <= 12 ? str : str.substring(0, 12) + '*'.repeat(str.length - 12);
            },
            grow: 4,
        },
        {
            name: '',
            cell: row => (
                <button onClick={() => copyToClipboard(row.apikey, row.id)}>
                    {copyStatus[row.id] || t('copy')}
                </button>
            ),
            button: true,
            grow: 2,
        },
        {
            name: '',
            cell: row => (
                <button style={{ color: 'red' }} onClick={() => handleClickDelete(row.id)}>
                    {t('delete')}
                </button>
            ),
            button: true,
            grow: 2,
        },
    ];

    return columns;
};