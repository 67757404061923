import { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useReactFlow } from 'reactflow';
import { useHandleChange } from './Hooks/useHandleChange';
import { useUpdateNodeDetails } from './Hooks/useUpdateNodeDetails';
import { useRenderCustomParams } from './Hooks/useRenderCustomParams';

const VariableNode = memo(({ data, id, type }) => {

    const { bp_func_alias, bpFunc } = data;

    const reactFlowInstance = useReactFlow();
    const [nodeDetails, setNodeDetails] = useState(null);

    const handleChange = useHandleChange(nodeDetails, setNodeDetails);

    const updateNodeDetails = useUpdateNodeDetails(reactFlowInstance);

    useEffect(() => {

        const newNodeDetails = reactFlowInstance.getNode(id);

        if (newNodeDetails && newNodeDetails.data.custom_params.length === 0) {

            const updatedParameters = [...newNodeDetails.data.custom_params, { id: id, name: '', value: '', type: 'string', direction: 'ParamOutput'}];

            newNodeDetails.data.custom_params = updatedParameters;
        }

        setNodeDetails(newNodeDetails);

    }, [setNodeDetails, reactFlowInstance, id]);

    useEffect(() => {

        if (nodeDetails) {
            updateNodeDetails(nodeDetails);
        }

    }, [nodeDetails, updateNodeDetails]);

    const renderCustomParams = useRenderCustomParams(nodeDetails, handleChange, data, id, type);
    
    return (
        <div className="bp-window">
            <div className="bp-label gradient-bg">
                {bp_func_alias || bpFunc}
            </div>

            <div className="bp-body">
                {renderCustomParams()}
            </div>
        </div>
    );
});

VariableNode.displayName = 'VariableNode';

VariableNode.propTypes = {
    data: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default VariableNode;