import React from 'react';

const InputDateComponent = ({ className, value, onChange }) => (
    <input
        className={`ml-2 input-style ${className}`}
        type="date"
        value={value}
        onChange={onChange}
        style={{ height: '40px', fontSize: '12px' }}
    />
);

export default InputDateComponent;