import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { fetchAllWorkflowIntancesByFilters } from '../../../Services/WorkflowService';

export const useFilterComponentHandleUpgradeBlueprint = (setPending, inputStartDate, inputEndDate, selectStatus, selectedWorkflow, handleRowSelected, setWorkflowInstancesComponent) => useCallback(async () => {

    setPending(true);

    try {

        let filters = {
            start_date: inputStartDate,
            end_date: inputEndDate,
            status: selectStatus,
            workflow_id: selectedWorkflow
        };

        const data = await fetchAllWorkflowIntancesByFilters(filters);

        setWorkflowInstancesComponent(data);

        handleRowSelected({ selectedRows: [] });

    } catch (error) {

        handleResponseError(error);

    } finally {

        setPending(false);

    }

}, [setPending, inputStartDate, inputEndDate, selectStatus, selectedWorkflow, handleRowSelected, setWorkflowInstancesComponent]);