const Node = () => {
    return {

        bpFunc: 'Branch',

        title: 'Branch',
        label: 'Branch',
        icon: '/icon_action.png',
        leftHandler: true,
        params: [
            {
                name: 'condition',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'output',
                value: '',
                type: 'boolean',
                required: true,
				direction: 'ParamOutput'
            },
        ]
    };
};

export default Node;