const Node = () => {
    return {

        bpFunc: 'Break',

        title: 'Break',
        label: 'Break',
        icon: '/icon_action.png',
        leftHandler: true,
        params: []
    };
};

export default Node;