import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Sidebar = ({ isDropdownOpen, setIsDropdownOpen }) => {

    const location = useLocation();

    const { t } = useTranslation();

    const links = [
        { to: '/automations', label: t('sidebar.automations') },
        { to: '/instances', label: t('sidebar.instances') },
        { to: '/integrations', label: t('sidebar.integrations') },
        { to: '', label: t('sidebar.settings'), dropdown: [
            { to: '/settings/api-keys', label: t('sidebar.settings.api-keys') }
        ]},
        { to: '/invoices', label: t('sidebar.invoices'), line: true }
    ];

    return (

        <aside>

            <nav>

                <div className="pt-4">
                
                    {links.map((link, index) => (
                        
                        <div key={index}>

                            {link.line && (
                                <hr className="sm:pl-6 mt-8"/>
                            )}

                            <Link 
                                to={link.to}
                                onClick={() => link.dropdown && setIsDropdownOpen(!isDropdownOpen)}
                                className={`${location.pathname === link.to ? 'border-l-4 border-primary' : 'ml-1 text-gray-600'} mt-5 hover:bg-gray-50 hover:text-gray-900 flex items-center justify-between px-6 py-2 text-base font-light rounded-md`}
                            >
                                <span className="truncate">{link.label}</span>
                                
                                {link.dropdown && (
                                    isDropdownOpen ? <span className="icon">&#9660;</span> : <span className="icon">&#9664;</span>
                                )}

                            </Link>
                            {link.dropdown && isDropdownOpen && (
                                <div className="dropdown-menu">
                                    {link.dropdown.map((dropdownLink, dropdownIndex) => (
                                        <Link
                                            key={dropdownIndex}
                                            to={dropdownLink.to}
                                            className={`${location.pathname === dropdownLink.to ? 'border-l-4 border-primary' : 'ml-1 text-gray-600'} "dropdown-link ml-10 mt-5 hover:bg-gray-100 hover:text-gray-900 flex items-center justify-between px-6 py-2 text-base font-light rounded-md`}
                                            style={{ width: '80%' }}
                                        >
                                            <span className="truncate">{dropdownLink.label}</span>
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}

                </div>
            </nav>

        </aside>
    );
};

Sidebar.displayName = 'Sidebar';

Sidebar.propTypes = {
    isDropdownOpen: PropTypes.bool.isRequired,
    setIsDropdownOpen: PropTypes.func.isRequired
};

export default Sidebar;