import { useEffect } from 'react';
import { ReactFlowProvider} from 'reactflow';
import { getOAuthToken, setAccessToken, redirectLogin } from './Services/AuthService';
import { useNavigate } from 'react-router-dom';

function OAuth2Callback() {
    
    const navigate = useNavigate();

    useEffect(() => {
        handleAuthentication(navigate);
    }, [navigate]);

    return (
        <ReactFlowProvider>
            <div className="flow-wrapper">
                <div className="flow-editor grid h-screen place-items-center text-gray-500">
                    Processando autenticação...
                </div>
            </div>
        </ReactFlowProvider>
    );
}

async function handleAuthentication(navigate: any) {
    
    const code = getCodeFromURL();

    if (code) {
    
        try {
            
            const token = await getOAuthToken(code);

            if (token) {
                setAccessToken(token);
                navigate('/');
            } else {
                console.error('Token não encontrado na resposta');
                redirectLogin();
            }
            
        } catch (error) {
            redirectLogin();
        }

    } else {
        redirectLogin();
    }
}

function getCodeFromURL() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('code');
}

export default OAuth2Callback;
