import ReactFlow, {
    Controls,
    Background,
    BackgroundVariant,
    Panel
} from 'reactflow';

import 'reactflow/dist/style.css';

import common from '../Editor/common';

import Sidebar from '../Editor/Sidebar';
import Header from './Header';
import NodeInspector from '../Editor/NodeInspector';
import AlertBar from './AlertBar';
import AlertErrorBar from './AlertErrorBar';
import ModalWorkflowInstanceEventsLogs from './ModalWorkflowInstanceEventsLogs';
import ModalWorkflowInstances from './ModalWorkflowInstances';
import styled from 'styled-components';

const ReactFlowStyled = styled(ReactFlow)`
  background-color: #0B2447;
`;

const ReactFlowWrapper = ({ updateWorkflowHandler, currentWorkflow, setShowModalWorkflowInstances, installedAppsActions, reactFlowWrapper, nodes, edges, setReactFlowInstance, onNodesChange, handleEdgesChange, onConnect, onDrop, onDragOver, minZoom, onNodeClick, maxZoom, fitViewOptions, nodeTypes, setNodeId, nodeId, updateConnectionLabel, RunWorkflow, setNodeDetails, nodeDetails, handleNodeChange, showAlert, setShowErrorAlert, alertMessage, setShowAlert, showEvents, setShowModalWorkflowInstanceEventsLogs, showErrorAlert, showModalWorkflowInstanceEventsLogs, workflowExecutionId, setLoading, handleResponseError, showModalWorkflowInstances, setWorkflowExecutionId }) => (
    
    <div className="flow-wrapper">
        <Header
            updateWorkflowHandler={updateWorkflowHandler}
            currentWorkflow={currentWorkflow}
            setShowModalWorkflowInstances={setShowModalWorkflowInstances}
        />
        <div className="flow-editor">

            <Sidebar installedAppsActions={installedAppsActions} currentWorkflow={currentWorkflow} />

            <div className="flow-editor-wrapper" ref={reactFlowWrapper}>
                <ReactFlowStyled nodes={nodes} edges={edges} onInit={setReactFlowInstance} onNodesChange={onNodesChange} onEdgesChange={handleEdgesChange} onConnect={onConnect} onDrop={onDrop} onDragOver={onDragOver} minZoom={minZoom} onNodeClick={onNodeClick} maxZoom={maxZoom} fitViewOptions={fitViewOptions} nodeTypes={nodeTypes} isValidConnection={common.useValidatorFn}>
                    <Panel position="top-right"><NodeInspector setNodeId={setNodeId} nodeId={nodeId} edges={edges} currentWorkflow={currentWorkflow} updateConnectionLabel={updateConnectionLabel} RunWorkflow={RunWorkflow} setNodeDetails={setNodeDetails} nodeDetails={nodeDetails} handleChange={handleNodeChange} /></Panel>
                    <Background id="1" gap={10} color="#001f2b" variant={BackgroundVariant.Lines} />
                    <Background id="2" gap={100} offset={1} color="#163162" variant={BackgroundVariant.Lines} />
                    <Controls showInteractive={false} />

                    {showAlert && <AlertBar setShowErrorAlert={setShowErrorAlert} message={alertMessage} setShowAlert={setShowAlert} showAlert={showAlert} showEvents={showEvents} setShowModalWorkflowInstanceEventsLogs={setShowModalWorkflowInstanceEventsLogs} />}
                    {showErrorAlert && <AlertErrorBar message={alertMessage} setShowErrorAlert={setShowErrorAlert} setShowAlert={setShowAlert} showErrorAlert={showErrorAlert} showEvents={showEvents} setShowModalWorkflowInstanceEventsLogs={setShowModalWorkflowInstanceEventsLogs} />}
                    
                    {showModalWorkflowInstanceEventsLogs && <ModalWorkflowInstanceEventsLogs currentWorkflow={currentWorkflow} workflowExecutionId={workflowExecutionId} setShowModalWorkflowInstanceEventsLogs={setShowModalWorkflowInstanceEventsLogs} showModalWorkflowInstanceEventsLogs={showModalWorkflowInstanceEventsLogs} setLoading={setLoading} handleResponseError={handleResponseError} />}
                    {showModalWorkflowInstances && <ModalWorkflowInstances setWorkflowExecutionId={setWorkflowExecutionId} currentWorkflow={currentWorkflow} setShowModalWorkflowInstances={setShowModalWorkflowInstances} showModalWorkflowInstances={showModalWorkflowInstances} setLoading={setLoading} handleResponseError={handleResponseError} setShowModalWorkflowInstanceEventsLogs={setShowModalWorkflowInstanceEventsLogs} setShowAlert={setShowAlert} />}
                </ReactFlowStyled>
            </div>
        </div>
    </div>
);

export default ReactFlowWrapper;