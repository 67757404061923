import { useCallback } from 'react';
import { handleResponseError } from '../../../Services/AuthService';
import { fetchWorkflowExecutionById } from '../../../Services/WorkflowService';

export const useGetWorkflowExecutionById = (setPending, setDataComponent) => useCallback(async (workflowExecutionId) => {

    setPending(true);

    try {

        const data = await fetchWorkflowExecutionById(workflowExecutionId);

        setDataComponent(data);

    } catch (error) {

        handleResponseError(error);

    } finally {

        setPending(false);

    }

}, [setPending, setDataComponent]);