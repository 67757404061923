import { handleResponseError } from '../../../Services/AuthService';
import { deleteWorkflow } from '../../../Services/WorkflowService';

export const useHandlerDeleteWorkflow = (setLoading, setShowModalDelete, getWorkflows, setInputDelete) => async (WorkflowId) => {

    setLoading(true);
    setShowModalDelete(false);

    try {

        await deleteWorkflow(WorkflowId);

        localStorage.removeItem('workflow_id');
        localStorage.removeItem('workflow');

        getWorkflows();

    } catch (error) {

        handleResponseError(error);

    } finally {

        setLoading(false);
        setInputDelete('');

    }
};