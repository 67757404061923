import { useCallback } from 'react';

export const useUpdateConnectionLabel = (setEdges) => {

    const updateConnectionLabel = useCallback((edgeId, label) => {
        
        setEdges((eds) =>
            eds.map((edge) => {
                if (edge.id === edgeId) {
                    return { ...edge, label: label };
                }
                return edge;
            })
        );

    }, [setEdges]);

    return updateConnectionLabel;
};