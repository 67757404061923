import React from 'react';

const SelectComponent = ({ className, options, value, onChange, disabledOptionLabel }) => (
    <select
        className={`ml-2 input-style ${className}`}
        value={value}
        onChange={onChange}
        style={{ height: '40px', fontSize: '12px' }}
    >
        <option disabled value="">{disabledOptionLabel}</option>
        {options.map((option) => (
            <option key={option.value} value={option.value}>
                {option.label}
            </option>
        ))}
    </select>
);

export default SelectComponent;
