import { useEffect } from 'react';
import { refreshToken, setAccessToken } from './Services/AuthService';

const useRefreshToken = () => {

    useEffect(() => {

        console.log('Setting up token refresh interval');

        const interval = 4 * 60 * 1000;

        const intervalId = setInterval(async () => {

            console.log('Refreshing token', new Date().toISOString());
            
            const newToken = await refreshToken();

            if (newToken) {
                setAccessToken(newToken);
                console.log('Token: ', newToken);
            }
            
        }, interval);

        return () => clearInterval(intervalId);

    }, []);

};

export default useRefreshToken;

