import { useCallback } from 'react';
import { useReactFlow, getConnectedEdges } from 'reactflow';

/**
 * Common usage functions for react-flow
 */
export default class ReactFlowCommon {
  useValidatorFn = () => {
    const { getNode, getEdges } = useReactFlow();

    return useCallback(
      (connection) => {
        const edges = getConnectedEdges([getNode(connection.target)], getEdges());

        for (let i = 0; i < edges.length; i++) {
          if (edges[i].sourceHandle === connection.sourceHandle || edges[i].targetHandle === connection.targetHandle) {
            console.log('handle already used');
            return false;
          }
        }
        return true;
      },
      [getNode, getEdges]
    );
  };
}
