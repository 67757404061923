import { useTranslation } from 'react-i18next';
import ButtonRedirect from '../../Components/ButtonRedirect';

const getTypeContent = (t, typeBlueprint) => {
    if (typeBlueprint === 'blueprint') {
        return (
            <>
                <div className="flex items-center space-x-2">
                    <img src="https://static.thenounproject.com/png/6676971-200.png" width="20" alt="Blueprint" />
                    <span>Blueprint</span>
                </div>
            </>
        );
    } else if (typeBlueprint === 'function') {
        return (
            <>
                <div className="flex items-center space-x-2">
                    <img src="https://static.thenounproject.com/png/2342347-200.png" width="20" alt="Função" />
                    <span>{ t('function') }</span>
                </div>
            </>
        );
    } else {
        return <span>{ t('undefined') }</span>;
    }
};

export const useDatatableColumnsWorkflow = (setWorkflow, setShowModalDelete, setShowModalEdit) => {

    const { t } = useTranslation();
    
    const handleClickDelete = (row) => {
        setWorkflow(row);
        setShowModalDelete(true);
    };

    const handleClickEdit = (row) => {
        setWorkflow(row);
        setShowModalEdit(true);
    };

    const columns = [
        {
            name: <span className="text-base">{t('automation')}</span>,
            selector: row => row.title,
            grow: 4,
        },
        {
            name: <span className="text-base">{t('type')}</span>,
            selector: row => getTypeContent(t, row.type),
            grow: 4,
        },
        {
            name: '',
            cell: row => <button onClick={() => handleClickEdit(row)}>{t('edit')}</button>,
            button: true,
            grow: 2,
        },
        {
            name: '',
            button: true,
            cell: (row) => (
                <button style={{ color: 'red' }} onClick={() => handleClickDelete(row)}>
                    {t('delete')}
                </button>
            ),
            grow: 2,
        },
    ];

    return columns;
};