const Node = () => {
    return {
        bpFunc: 'SubtractDate',

        title: 'SubtractDate',
        label: 'SubtractDate',
        icon: '/icon_action.png',
        leftHandler: true,
        params: [
            {
                name: 'original_date',
                alias: 'Original Date',
                value: '',
                placeholder: 'YYYY-MM-DD',
                type: 'string',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'days_to_subtract',
                alias: 'Days to Subtract',
                value: 1,
                type: 'integer',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'date',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            },
            {
                name: 'date_ptbr',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            },
            {
                name: 'day',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            },
            {
                name: 'month',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            },
            {
                name: 'year',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            },
        ]
    };
};

export default Node;