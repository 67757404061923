import { useCallback } from 'react';

function checkInstanceEditConditions(selectedRows) {
    return selectedRows && selectedRows.every(row => row.workflow_error || row.level === 'WAITING' || row.level === 'RUNNING' || row.level === 'STOPPED');
}

export const useHandleRowSelected = (setSelectedRows, setSelectedWorkflow, setFilterDisabled) => useCallback((state) => {

    if (state.selectedRows.length > 0) {
        setSelectedWorkflow(state.selectedRows[0].workflow_id);
    }
    
    setSelectedRows(state.selectedRows);

    const allWorkflowsMatch = state.selectedRows.length > 0 && state.selectedRows.every(row => row.workflow_id === state.selectedRows[0].workflow_id);

    const shouldDisableFilter = !checkInstanceEditConditions(state.selectedRows) || !allWorkflowsMatch;

    if (shouldDisableFilter) {
        setSelectedWorkflow('');
    }

    setFilterDisabled(shouldDisableFilter);

}, [setSelectedRows, setSelectedWorkflow, setFilterDisabled]);