import PropTypes from 'prop-types';

function MarketplaceApps({ 
    onDragStart, 
    installedAppsActions
}) {

    return (
        <div>
            {installedAppsActions && installedAppsActions.length > 0 && installedAppsActions.map(app => (
                <button
                    key={app.name}
                    className="dndnode flex gap-1 items-center justify-center"
                    onDragStart={(event) => onDragStart(event, {'id': 'marketplace'}, app)}
                    draggable
                >
                    {app.title}
                </button>
            ))}
        </div>
    );
}


MarketplaceApps.displayName = 'MarketplaceApps';

MarketplaceApps.propTypes = {
    installedAppsActions: PropTypes.array,
    onDragStart: PropTypes.func.isRequired
};

export default MarketplaceApps;