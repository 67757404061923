import axios from '../Config/axios';

import { bpClientId, bpRedirectUri, bpAuthEndpoint } from '../Config/variables';

export async function getOAuthToken(code) {
    
    try {

        const payload = { code };
        
        const response = await axios.post('/oauth2/token', payload);
        
        return response.data;
    
    } catch (error) {
    
        console.error('Erro ao obter o token OAuth', error);
    
        throw error;
    
    }
}

export async function refreshToken() {
    
    try {

        const refresh_token = localStorage.getItem('refresh_token');

        if (!refresh_token) {
            throw new Error('Refresh token não encontrado');
        }

        const payload = { code: refresh_token };
        
        const response = await axios.post('/refresh/token', payload);

        return response.data;
    
    } catch (error) {
        console.error('Erro ao atualizar o token OAuth', error);
    }
}

export const setAccessToken = (token) => {

    const { access_token, refresh_token, expires_in } = token;

    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);

    const expiresIn = expires_in;
    const expiryTimestamp = Date.now() + (expiresIn * 1000);
    localStorage.setItem('expires_in', expiryTimestamp.toString());

};

export const setWorkspace = (workspaceId, workspaceName) => {    
    if(workspaceId || workspaceName) {
        localStorage.setItem('workspace_id', workspaceId);
        localStorage.setItem('workspace_name', workspaceName);
    }
};

export const handleResponseError = (error) => {
    redirectLogin();
};

export const redirectLogin = () => {
    
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('lastPath');

    const link = `${bpAuthEndpoint}?client_id=${bpClientId}&redirect_uri=${bpRedirectUri}&response_type=code`;

    window.location.href = link;
};

export const updateAuthOptions = (node, options) => {

    const authIndex = node.data.params.findIndex(p => p.name === 'auth');

    if (authIndex === -1) return node;

    const authParam = node.data.params[authIndex];
    const isValueInOptions = options.some(option => option.value === authParam.value);

    if (!isValueInOptions) {

        node.data.params[authIndex].value = '';
    }

    node.data.params[authIndex].options = options;

    return node;
};