import { useState, useEffect, useRef } from 'react';
import {
    useNodesState,
    useEdgesState,
    ReactFlowProvider
} from 'reactflow';

import 'reactflow/dist/style.css';
import { useParams } from 'react-router-dom';

import { handleResponseError } from '../../Services/AuthService';
import { isValidFlow, setMaxIdNode, setMaxIdEdge } from './Utils';

import { useUpdateConnectionLabel } from './Hooks/useUpdateConnectionLabel';
import { useWorkflowNodeInjector } from './Hooks/useWorkflowNodeInjector';
import { useHandleNodeChange } from './Hooks/useHandleNodeChange';
import { useHandleNodeClick } from './Hooks/useHandleNodeClick';
import { useHandleUpdateNodeParamValue } from './Hooks/useHandleUpdateNodeParamValue';
import { useOnNodesChange } from './Hooks/useOnNodesChange';
import { useHandleEdgesChange } from './Hooks/useHandleEdgesChange';
import { useOnConnect } from './Hooks/useOnConnect';
import { useOnDrop } from './Hooks/useOnDrop';
import { useOnDragOver } from './Hooks/useOnDragOver';

import { useGetWorkflowById } from './Hooks/useGetWorkflowById';
import { useUpdateWorkflowHandler } from './Hooks/useUpdateWorkflowHandler';
import { useRunWorkflow } from './Hooks/useRunWorkflow';

import { useGetInstalledAppsActions } from './Hooks/useGetInstalledAppsActions';
import { useHandleMarketplaceType } from './Hooks/useHandleMarketplaceType';

import { initialNodes, initialEdges, nodeTypes } from './Constants';
import ReactFlowWrapper from '../Components/ReactFlowWrapper';

const Interface = () => {

    const fitViewOptions = { padding: 4 };

    const reactFlowWrapper = useRef(null);

    const { workflowId } = useParams();

    const [nodes, setNodes] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [nodeId, setNodeId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentWorkflow, setCurrentWorkflow] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [showEvents, setShowEvents] = useState(true);
    const [alertMessage, setAlertMessage] = useState('');
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showModalWorkflowInstanceEventsLogs, setShowModalWorkflowInstanceEventsLogs] = useState(false);
    const [workflowExecutionId, setWorkflowExecutionId] = useState('');
    const [showModalWorkflowInstances, setShowModalWorkflowInstances] = useState(false);
    
    const [nodeDetails, setNodeDetails] = useState(null);
    const [installedAppsActions, setInstalledAppsActions] = useState(null);

    const onDragOver = useOnDragOver();

    const updateConnectionLabel = useUpdateConnectionLabel(setEdges);

    const handleNodeClick = useHandleNodeClick(setNodes, setNodeDetails);

    const { addNodesIfNeededForBlueprintFunction } = useWorkflowNodeInjector(setNodes);

    const onNodesChange = useOnNodesChange(setNodes, nodes);

    const getInstalledAppsActions = useGetInstalledAppsActions(setInstalledAppsActions);

    const handleMarketplaceType = useHandleMarketplaceType(installedAppsActions);

    const updateNodeParamValue = useHandleUpdateNodeParamValue(setNodes, setNodeDetails);

    const onConnect = useOnConnect(edges, nodes, setNodes, updateNodeParamValue, setEdges);

    const handleEdgesChange = useHandleEdgesChange(edges, nodes, setNodes, updateNodeParamValue, onEdgesChange);

    const getWorkflowById = useGetWorkflowById(setCurrentWorkflow, setEdges, setNodes, setLoading, reactFlowInstance, setMaxIdNode, setMaxIdEdge);

    const updateWorkflowHandler = useUpdateWorkflowHandler(reactFlowInstance, setLoading, setShowEvents, setAlertMessage, setShowAlert, setShowErrorAlert, setNodes, setEdges, setMaxIdNode, setMaxIdEdge, setCurrentWorkflow, currentWorkflow);

    const RunWorkflow = useRunWorkflow(reactFlowInstance, currentWorkflow, setLoading, isValidFlow, setWorkflowExecutionId, setShowEvents, setAlertMessage, setShowAlert);

    const onDrop = useOnDrop(setNodes, handleMarketplaceType, reactFlowInstance, reactFlowWrapper, setCurrentWorkflow, currentWorkflow);

    const handleNodeChange = useHandleNodeChange(nodeDetails, reactFlowInstance, setNodeDetails);

    useEffect(() => {

        if (!currentWorkflow || (currentWorkflow && currentWorkflow.id !== workflowId)){
            getWorkflowById(workflowId);
        }
        
        if (!installedAppsActions){
            getInstalledAppsActions();
        }
        
        if (nodes.length === 0 && currentWorkflow && currentWorkflow.type === 'function' && !currentWorkflow.workflow_json){
            addNodesIfNeededForBlueprintFunction();
        }

    }, [getWorkflowById, workflowId, currentWorkflow, getInstalledAppsActions, installedAppsActions, addNodesIfNeededForBlueprintFunction, nodes]);

    return (
        <ReactFlowProvider>

            {loading &&
                <div className="flow-editor grid h-screen place-items-center text-gray-500">
                    Loading
                </div>
            }

            <ReactFlowWrapper updateWorkflowHandler={updateWorkflowHandler} currentWorkflow={currentWorkflow} setShowModalWorkflowInstances={setShowModalWorkflowInstances} installedAppsActions={installedAppsActions} reactFlowWrapper={reactFlowWrapper} nodes={nodes} edges={edges} setReactFlowInstance={setReactFlowInstance} onNodesChange={onNodesChange} handleEdgesChange={handleEdgesChange} onConnect={onConnect} onDrop={onDrop} onDragOver={onDragOver} minZoom={0.2} onNodeClick={handleNodeClick} maxZoom={1.0} fitViewOptions={fitViewOptions} nodeTypes={nodeTypes} setNodeId={setNodeId} nodeId={nodeId} updateConnectionLabel={updateConnectionLabel} RunWorkflow={RunWorkflow} setNodeDetails={setNodeDetails} nodeDetails={nodeDetails} handleNodeChange={handleNodeChange} showAlert={showAlert} setShowErrorAlert={setShowErrorAlert} alertMessage={alertMessage} setShowAlert={setShowAlert} showEvents={showEvents} setShowModalWorkflowInstanceEventsLogs={setShowModalWorkflowInstanceEventsLogs} showErrorAlert={showErrorAlert} showModalWorkflowInstanceEventsLogs={showModalWorkflowInstanceEventsLogs} workflowExecutionId={workflowExecutionId} setLoading={setLoading} handleResponseError={handleResponseError} showModalWorkflowInstances={showModalWorkflowInstances} setWorkflowExecutionId={setWorkflowExecutionId}
            />

        </ReactFlowProvider>
    );
}

export default Interface;