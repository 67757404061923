import React from 'react';
import { Handle, Position } from 'reactflow';
import DisplayTypeComponent from './DisplayTypeComponent';
import EditableDisplayName from './EditableDisplayName';
import NodeIcon from '../Components/NodeIcon';
import PropTypes from 'prop-types';

const ParamsDisplayComponent = ({ handle, icon, title, targetHandle, sourceHandle, outputParams, baseId, updateParamName }) => {

    return (

        <>

            <NodeIcon nodeIcon={icon} />

            <div className="bp-body">

                <div className='text-title node-header'>
                    {title}
                </div>

                <div className='pt-3 bg-output-node'>

                    <h1 className='text-title pb-3'>Saída:</h1>

                    {outputParams.filter(param => param.name !== 'callback_url' && param.name !== 'callback_app_id').map((param, index) => {

                        const handleId = `handleId-${baseId}-${index}`;
                        
                        return (

                            <React.Fragment key={handleId}>

                                <div key={`div-${baseId}`} className="flex flex-col justify-between margin-fragment">

                                    <div className="flex justify-between">

                                        <EditableDisplayName
                                            key={index}
                                            index={index}
                                            param={param}
                                            onUpdate={updateParamName}
                                        />

                                        <span className='text-value'>
                                            <DisplayTypeComponent key={`display-${baseId}`} type={param.type} />
                                        </span>

                                    </div>

                                    {
                                        handle === 'in' ? (

                                            <div style={{ display: 'flex' }}>

                                                <Handle
                                                    type="target"
                                                    className="react-flow__handle--in handle-source-left"
                                                    key={handleId}
                                                    position={Position.Left}
                                                    id={handleId}
                                                />

                                            </div>

                                        ) : (

                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                <Handle
                                                    type="source"
                                                    className="react-flow__handle--out handle-source-right"
                                                    key={handleId}
                                                    position={Position.Right}
                                                    id={handleId}
                                                />

                                            </div>
                                        )
                                    }

                                </div>

                            </React.Fragment>
                        );
                    })}

                </div>

            </div>

            {sourceHandle}

            {targetHandle}
        </>
    );
};

ParamsDisplayComponent.displayName = 'ParamsDisplayComponent';

ParamsDisplayComponent.propTypes = {
    handle: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    targetHandle: PropTypes.object,
    sourceHandle: PropTypes.object,
    outputParams: PropTypes.array.isRequired,
    baseId: PropTypes.string.isRequired,
    updateParamName: PropTypes.func.isRequired,
};

export default ParamsDisplayComponent;