import { memo } from 'react';
import { Handle, Position } from 'reactflow';
import InputDisplay from '../Components/InputDisplay';
import NodeIcon from '../Components/NodeIcon';
import PropTypes from 'prop-types';

const BranchNode = memo(({ data, id, type }) => {
    
    const inputParams = data.params.filter(param => param.direction === 'ParamInput' && !param.hidden);
    
    return (

        <div className="bp-window" style={{ height: '155px' }}>
            
            <div className="bp-label">{data.bp_func_alias ? data.bp_func_alias : data.bpFunc}</div>
            
            <NodeIcon nodeIcon={data.icon}/>
            
            <div className="bp-body">
                
                <div className='text-title' style={{ backgroundColor: '#f5f5f5', padding: '5px', borderRadius: '5px', marginBottom: '10px', textAlign: 'center' }}>
                    {data.title}
                </div>
                
                {inputParams.length > 0 && <InputDisplay dataForms={data} params={inputParams} nodeId={id} nodeType={type} fieldType="input_params"/>}

            </div>

            <Handle type="target" className="react-flow__handle--in" position={Position.Left} id="in" />

            <Handle type="source" position={Position.Right} style={{ top: '40px' }} id="true">
                <span className="handletext">true</span>
            </Handle>

            <Handle type="source" position={Position.Right} style={{ top: '52px' }} id="false">
                <span className="handletext">false</span>
            </Handle>
        </div>

    );
});

BranchNode.displayName = 'BranchNode';

BranchNode.propTypes = {
    data: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default BranchNode;