const Node = () => {
    return {

        bpFunc: 'Unset',

        title: 'Unset',
        label: 'Unset',
        icon: '/icon_print.png',
        leftHandler: true,
        params: [
            {
                name: 'string',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamInput'
            }
        ],
        hide_output: true,
    };
};

export default Node;