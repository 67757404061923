import { useState, useEffect } from 'react';
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import DataTable from 'react-data-table-component';
import Header from './Components/Header';
import Sidebar from './Components/Sidebar';
import DeleteWorkflowModal from './Components/DeleteWorkflowModal';
import SyncButton from './Components/SyncButton';
import { useGetWorkflows } from './Editor/Hooks/useGetWorkflows';
import { useHandlerCreateWorkflow } from './Editor/Hooks/useHandlerCreateWorkflow';
import { useHandlerDeleteWorkflow } from './Editor/Hooks/useHandlerDeleteWorkflow';
import { useTranslation } from 'react-i18next';
import { useDatatableColumnsWorkflow } from './Editor/Hooks/useDatatableColumnsWorkflow';
import WorkflowDropdownButton from './Components/WorkflowDropdownButton';
import Loading from './Components/Loading';
import PageHeader from './Components/PageHeader';
import { customRowStyles } from './Editor/js/styles';
import { useNavigate } from 'react-router-dom';
import CreateWorkflowModal from './Components/CreateWorkflowModal';
import EditWorkflowModal from './Components/EditWorkflowModal';
import { useHandlerUpdateWorkflow } from './Editor/Hooks/useHandlerUpdateWorkflow';

const workflowStyles = {
    ...customRowStyles,
    rows: {
        ...customRowStyles.rows,
        style: {
            ...customRowStyles.rows.style,
            cursor: 'pointer',
        }
    }
};

const Workflows = () => {

    const [loading, setLoading] = useState(false);
    const [workflowsComponent, setWorkflowsComponent] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [typeBlueprint, setTypeBlueprint] = useState(1);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [workflow, setWorkflow] = useState('');
    const [inputDelete, setInputDelete] = useState('');
    const [pending, setPending] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [isPublic, setIsPublic] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);

    const navigate = useNavigate();

    const { t } = useTranslation();

    const columns = useDatatableColumnsWorkflow(setWorkflow, setShowModalDelete, setShowModalEdit)

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const breadcrumbItems = [
        { label: 'Dashboard', link: '/dashboard' },
        { label: t('sidebar.automations'), link: '/automations' }
    ];

    const getWorkflows = useGetWorkflows(setPending, setWorkflowsComponent);

    const createWorkflowHandler = useHandlerCreateWorkflow(setLoading, setShowModal, getWorkflows, isPublic, setIsPublic);

    const updateWorkflowHandler = useHandlerUpdateWorkflow(setLoading, setShowModalEdit, getWorkflows, isPublic, setIsPublic);

    const deleteWorkflowHandler = useHandlerDeleteWorkflow(setLoading, setShowModalDelete, getWorkflows, setInputDelete);

    const handleTypeBlueprint = (type) => {
        setShowDropdown(false);
        setTypeBlueprint(type);
        setShowModal(true);
    };

    const rightContent = (
        <WorkflowDropdownButton handleTypeBlueprint={handleTypeBlueprint} showModal={showModal} showDropdown={showDropdown} setShowDropdown={setShowDropdown} setShowModal={setShowModal} createWorkflowHandler={createWorkflowHandler} typeBlueprint={typeBlueprint} />
    );

    useEffect(() => {
        getWorkflows();
    }, [getWorkflows]);

    return (

        <ReactFlowProvider>

            {
                loading ? (
                    <Loading />
                ) : (

                    <div className="flow-wrapper">

                        <Header />

                        <div className="flow-editor">

                            <Sidebar isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} />

                            <div className="flow-editor-wrapper">

                                <PageHeader title={t('sidebar.automations')} breadcrumbItems={breadcrumbItems} rightContent={rightContent}/>

                                {showModalDelete &&
                                    <DeleteWorkflowModal setShowModalDelete={setShowModalDelete} deleteWorkflowHandler={deleteWorkflowHandler} workflowId={workflow.id} inputDelete={inputDelete} setInputDelete={setInputDelete}/>
                                }

                                {showModal && 
                                    <CreateWorkflowModal setShowModal={setShowModal} createWorkflowHandler={createWorkflowHandler} typeBlueprint={typeBlueprint} isPublic={isPublic} setIsPublic={setIsPublic} workflow={workflow} />
                                }

                                {showModalEdit && 
                                    <EditWorkflowModal setShowModalEdit={setShowModalEdit} updateWorkflowHandler={updateWorkflowHandler} typeBlueprint={typeBlueprint} isPublic={isPublic} setIsPublic={setIsPublic} workflow={workflow} />
                                }

                                <div className="relative m-10" style={{ border: '1px solid #e5e7eb', borderRadius: '0.375rem', padding: '2rem' }}>

                                    {!pending && ( <SyncButton onSync={getWorkflows} />  )}

                                    <DataTable columns={columns} onRowClicked={(row) => navigate(`/workflow/${row.id}`)} data={workflowsComponent} progressPending={pending} pagination persistTableHead noDataComponent={<div style={{ padding: 24 }}>{t('no_records')}</div>} customStyles={workflowStyles} />

                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        </ReactFlowProvider>
    );
};

export default Workflows;