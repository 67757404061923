const Node = () => {
    return {
        bpFunc: 'For',

        title: 'For',
        label: 'For',
        icon: '/icon_action.png',
        leftHandler: true,
        params: [
            {
                name: 'start',
                value: '',
                type: 'integer',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'end',
                value: '',
                type: 'integer',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'current',
                value: '',
                type: 'integer',
                required: true,
				direction: 'ParamInput'
            }
        ]
    };
};

export default Node;