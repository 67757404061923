import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Breadcrumb = ({ items }) => {
    return (
        <div className="flex items-center mt-5">
            {items.map((item, index) => {
                const isLastItem = index === items.length - 1;
                return (
                    <React.Fragment key={index}>
                        {!isLastItem ? (
                            <>
                                <Link to={item.link} className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                    {item.label}
                                </Link>
                                <ArrowIcon />
                            </>
                        ) : (
                            <span className={`text-sm font-medium ${isLastItem ? 'text-gray-900' : 'text-gray-500'}`}>
                                {item.label}
                            </span>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};


const ArrowIcon = () => (
    <svg className="ml-4 mr-4 h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd"></path>
    </svg>
);

Breadcrumb.displayName = 'Breadcrumb';

Breadcrumb.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default Breadcrumb;
