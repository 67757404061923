
import ActionNode from './ActionNode';
import VariableNode from './VariableNode';
import OutputNode from './OutputNode';
import InputNode from './InputNode';
import BlueprintNode from './BlueprintNode';
import TriggerNode from './TriggerNode';
import BranchNode from './BranchNode';
import SwitchNode from './SwitchNode';

export const nodeTypes = {
    action: ActionNode,
    trigger: TriggerNode,
    branch: BranchNode,
    switch: SwitchNode,
    variable: VariableNode,
    blueprint: BlueprintNode,
    input_function: InputNode,
    output_function: OutputNode,
};

export const initialNodes = [];

export const initialEdges = [];