import { useTranslation } from 'react-i18next';

const WorkflowDropdownButton = ({ handleTypeBlueprint, showDropdown, setShowDropdown }) => {

    const { t } = useTranslation();

    return (

        <>

            <div className="relative inline-block text-left">
                <button onClick={() => setShowDropdown(!showDropdown)} className="flex items-center bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-10 ease-linear transition-all duration-150" type="button">
                    {t('create_automation')}
                    <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </button>

                {showDropdown && (
                    <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 mr-10">
                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                            <button onClick={() => handleTypeBlueprint(1)} className="flex items-center space-x-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full " role="menuitem">
                                <img src="https://static.thenounproject.com/png/6676971-200.png" width="20" alt="Blueprint" /> <span>Blueprint</span>
                            </button>
                            <button onClick={() => handleTypeBlueprint(2)} className="flex items-center space-x-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full " role="menuitem">
                                <img src="https://static.thenounproject.com/png/2342347-200.png" width="20" alt={ t('function') } /> <span>{ t('function') }</span>
                            </button>
                        </div>
                    </div>

                )}
            </div>
        </>

    );
};

export default WorkflowDropdownButton;