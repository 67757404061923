import { useCallback } from 'react';

export const usePushSelectWorkflow = (setCurrentWorkflow, loadFlow) => useCallback(async (workflow) => {
    
    localStorage.setItem('workflow_id', workflow.id);

    setCurrentWorkflow(workflow);

    if (workflow.workflow_json) {
        const flow = JSON.parse(workflow.workflow_json);
        loadFlow(flow);
    }

}, [setCurrentWorkflow, loadFlow]);