import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const CreateWorkflowModal = ({ setShowModal, createWorkflowHandler, typeBlueprint }) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-6xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <form onSubmit={createWorkflowHandler}>
                            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold pr-10 pl-10 pt-5">
                                    {typeBlueprint === 1 ? t('create_new_blueprint') : t('create_new_function')}
                                </h3>
                            </div>

                            <div className="relative p-6 flex-auto">
                                <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                    <div>
                                        
                                        {t('enter_a_name')}

                                        <input required type="text" placeholder="" name='title' id='title' className="px-3 py-3 mt-2 placeholder-slate-600 text-slate-800 relative bg-white bg-white rounded text-sm border-1 shadow outline-none focus:outline-none focus:ring w-full" />
                                        
                                        {typeBlueprint === 1 ? (
                                            <>
                                                <input type="hidden" name='type' value="blueprint"/>
                                            </>
                                        ) : (
                                            <>
                                                <input type="hidden" name='type' value="function"/>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => setShowModal(false)}>
                                    {t('close')}
                                </button>
                                <button className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="submit">
                                    {t('start')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

CreateWorkflowModal.displayName = 'CreateWorkflowModal';

CreateWorkflowModal.propTypes = {
    setShowModal: PropTypes.func.isRequired,
    createWorkflowHandler: PropTypes.func.isRequired,
    typeBlueprint: PropTypes.number.isRequired
};

export default CreateWorkflowModal;