const Node = () => {
    return {
        bpFunc: 'Delay',

        title: 'Delay',
        label: 'Delay',
        icon: '/icon_action.png',
        leftHandler: true,
        params: [
            {
                name: 'seconds',
                value: '',
                type: 'integer',
                required: true,
				direction: 'ParamInput'
            }
        ]
    };
};

export default Node;