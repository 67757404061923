import React, { useState, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import DisplayTypeComponent from './DisplayTypeComponent';
import PropTypes from 'prop-types';

function InputDisplay({ dataForms, params, nodeId, nodeType, fieldType }) {

    const [inputParams, setInputParams] = useState([]);

    const { bpFunc, leftHandler } = dataForms;

    useEffect(() => {

        let paramsName = {};
        
        params.forEach((param, index) => {

            if(!param.previousName){
                param.previousName = param.name;
            }

            param.displayName = param.alias || param.name;
            
            param.displayValue = param.value && param.value.length > 10 ? `${param.value.slice(0, 10)}...` : param.value;

            param.index = index;

            params[index] = param;

            paramsName[param.previousName] = param;

        });

        setInputParams(Object.values(paramsName));

    }, [params]);

    const generateNodeId = (bpFunc) => {
        return `${nodeId}-${bpFunc}-${nodeType}-${fieldType}`.replace(/\s+/g, '-').toLowerCase();
    };

    const baseId = generateNodeId(bpFunc);

    if (inputParams.length === 0) {
        return null;
    }

    return (

        <div className='pt-3 bg-input-node'>
            
            <h1 className='pb-3 text-title'>Entrada:</h1>
            
            {inputParams.map((param) => {     
                
                if (param.hidden) return null;

                const handleId = `handleId-${baseId}-${param.index}`;

                return (

                    <React.Fragment key={handleId}>

                        <div key={`div-${baseId}`} className="flex flex-col justify-between margin-fragment">
                            
                            <div>
                                <div className="flex justify-between">
                                    <label><b><span className='text-label'>{param.displayName}</span> {param.required && <span style={{ color: '#e74c3c' }}>*</span>}</b></label>
                                    <span className='text-value'>{<DisplayTypeComponent key={`display-${baseId}`} value={param.displayValue} />}</span>
                                </div>

                                <div style={{ display: 'flex'}}>
                                
                                    {leftHandler && (
                                        <div style={{ display: 'flex' }}>
                                            <Handle
                                                type="target"
                                                className="react-flow__handle--in"
                                                key={handleId}
                                                position={Position.Left}
                                                id={handleId}
                                                style={{ 
                                                    background: '#555',
                                                    position: 'relative',
                                                    left: '-20px',
                                                    top: '-10px'
                                                }}
                                            />
                                        </div>
                                    )}

                                </div>
                            </div>
                
                        </div>

                    </React.Fragment>
                );

            })}

        </div>
    );
    
}

InputDisplay.propTypes = {
    dataForms: PropTypes.shape({
        leftHandler: PropTypes.bool,
        bpFunc: PropTypes.string.isRequired
    }).isRequired,
    params: PropTypes.array.isRequired,
    nodeId: PropTypes.string.isRequired,
    nodeType: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired
};

export default InputDisplay;
