import { useCallback } from 'react';
import { applyNodeChanges } from 'reactflow';

export const useOnNodesChange = (setNodes, nodes) => useCallback((changes) => {

    if (changes.length > 1) {

        changes.forEach(change => {

            if (changes[change.id] && changes[change.id].item) {
                const sourceNode = nodes.find((node) => node.id === changes[change.id].item.id);
                changes[change.id].item.data.params = sourceNode.params;
            }
        });
    }

    setNodes((nds) => applyNodeChanges(changes, nds));

}, [setNodes, nodes]);