const Node = () => {
    return {
        bpFunc: 'DatesOfMonth',

        title: 'DatesOfMonth',
        label: 'DatesOfMonth',
        icon: '/icon_action.png',
        leftHandler: true,
        params: [
            {
                name: 'year',
                value: '',
                type: 'integer',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'month',
                value: '',
                type: 'integer',
                required: true,
				direction: 'ParamInput'
            },
            {
                name: 'dates',
                value: '',
                type: 'string',
                required: true,
				direction: 'ParamOutput'
            }
        ]
    };
};

export default Node;