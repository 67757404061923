import { useTranslation } from 'react-i18next';

import SelectComponent from './SelectComponent';
import InputDateComponent from './InputDateComponent';

const HeaderComponent = ({
    selectedWorkflow,
    handleSelectWorkflow,
    inputStartDate,
    handleInputStartDate,
    inputEndDate,
    handleInputEndDate,
    selectStatus,
    handleSelectStatus,
    workflowsComponent,
    filterDisabled,
    selectedRows,
    FilterComponentHandleUpgradeBlueprint,
    EditComponentHandleUpgradeBlueprint
}) => {
    
    const { t } = useTranslation();

    const statusOptions = [
        { label: 'Todos', value: 'ALL' },
        { label: 'Error', value: 'ERROR' },
        { label: 'In Progress', value: 'RUNNING' },
        { label: 'Stopped', value: 'STOPPED' },
    ];

    return (
        <div className="flex justify-between items-center">
            <SelectComponent
                value={selectedWorkflow}
                onChange={handleSelectWorkflow}
                options={workflowsComponent.map((option) => ({ label: option.title, value: option.id }))}
                disabledOptionLabel={t('sidebar.automations')}
            />
            <InputDateComponent
                value={inputStartDate}
                onChange={handleInputStartDate}
            />
            <InputDateComponent
                value={inputEndDate}
                onChange={handleInputEndDate}
            />
            <SelectComponent
                value={selectStatus}
                onChange={handleSelectStatus}
                options={statusOptions}
                disabledOptionLabel={t('status')}
            />
            <button onClick={FilterComponentHandleUpgradeBlueprint} className="ml-2 bg-green-400 text-white active:bg-green-500 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                {t('btn.filter')}
            </button>
            <button
                onClick={() => selectedRows.length > 0 && EditComponentHandleUpgradeBlueprint()}
                className={`ml-2 bg-blue-500 ${filterDisabled ? 'disabled' : ''} text-white active:bg-blue-600 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ${selectedRows.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={filterDisabled}
            >
                {t('btn.edit')}
            </button>
        </div>
    );
};

HeaderComponent.displayName = 'HeaderComponent';

export default HeaderComponent;