import { useState, useEffect } from 'react';
import {ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';

import Header from './Components/Header';
import Sidebar from './Components/Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRetrieveWorkflows } from './Editor/Hooks/useGetWorkflows';
import Loading from './Components/Loading';

const Home = () => {

    const location = useLocation();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const getWorkflows = useRetrieveWorkflows(setLoading);

    useEffect(() => {

        const lastPath = localStorage.getItem('lastPath');

        if (location.pathname === '/' && lastPath && lastPath !== location.pathname) {
            navigate(lastPath);
        } else {
            getWorkflows();
        }

    }, [location.pathname, navigate, getWorkflows]);

    return (
        <ReactFlowProvider>

            {
                loading ? (
                    <Loading />
                ) : (

                    <div className="flow-wrapper">
                        
                        <Header />
                        
                        <div className="flow-editor">

                            <Sidebar isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen}/>

                            <div className="flow-editor-wrapper">
                                <div className="flex justify-between items-center"></div>
                            </div>

                        </div>
                    </div>
                )
            }

        </ReactFlowProvider>
    );
};

export default Home;