import { useCallback } from 'react';

export const useCalculatePosition = (reactFlowInstance, reactFlowWrapper) => useCallback((event) => {

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

    return reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left - 60,
        y: event.clientY - reactFlowBounds.top - 32,
    });
    
}, [reactFlowInstance, reactFlowWrapper]);