import React, { useEffect } from 'react';
import './App.css';
import BlueprintEditor from './Blueprint/Editor';
import OAuth2Callback from './OAuth2Callback';
import Home from './Blueprint/Home';
import Workflows from './Blueprint/Workflows';
import Instances from './Blueprint/Instances';
import Events from './Blueprint/Events';
import Integrations from './Blueprint/Integrations';
import APIKeys from './Blueprint/APIKeys';
import Invoices from './Blueprint/Invoices';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import useRefreshToken from './RefreshToken';

function App() {

    console.log('Starting app... ', new Date().toISOString());

    useRefreshToken();

    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        
        if (location.pathname !== '/' && location.pathname !== '/oauth2/callback') {
            localStorage.setItem('lastPath', location.pathname);
        }

    }, [navigate, location.pathname]);

    return (
        <div className="App">
               <Routes>
                <Route path="/oauth2/callback" element={<OAuth2Callback />} />
                <Route path="/workflow/:workflowId" element={<BlueprintEditor />} />
                <Route path="/" element={<Home />} />
                <Route path="/dashboard" element={<Home />} />
                <Route path="/automations" element={<Workflows />} />
                <Route path="/instances" element={<Instances />} />
                <Route path="/integrations" element={<Integrations />} />
                <Route path="/settings/api-keys" element={<APIKeys />} />
                <Route path="/workflows/instance/:workflowExecutionId" element={<Events />} />
                <Route path="/invoices" element={<Invoices />} />
            </Routes>
        </div>
    );
}

export default App;
